import React from 'react';
import { Button } from "@blueprintjs/core";
import DatePicker from 'react-mobile-datepicker';

import { Container } from './styles';

export default function DateTime(props) {
  return (
    <Container padding={props.padding}>
      <label>{props.label}</label>
      <Button
        icon={props.icon}
        intent="primary"
        onClick={props.onClickButton}
        disabled={props.disabled}
      />
      <DatePicker
        isOpen={props.isOpen}
        theme="android"
        confirmText="Confirmar"
        cancelText="Cancelar"
        headerFormat={props.headerFormat}
        showCaption={props.showCaption}
        dateConfig={props.dateConfig}
        min={props.min}
        max={props.max}
        value={props.value}
        onSelect={props.onSelect}
        onCancel={props.onCancel}
      />
    </Container>
  );
}
