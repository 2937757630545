import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Spinner, Button, Icon, NonIdealState } from "@blueprintjs/core";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilRuler,
  faTractor,
  faThermometer
} from "@fortawesome/free-solid-svg-icons";

import * as moment from "moment";
import * as mtz from "moment-timezone";

import NavBar from "../../../components/Navbar";
import Dialog from "../../../components/Dialog";
import DateTime from "../../../components/DateTime";
import ChartModal from "../../../components/ChartModal";

import { Container, Arvore, OpcoesGrafico, Opcoes, styles } from "./styles";
import ClientesStore from "../../../store/clientes";
import Api from "../../../services/api";
import LoginStore from "../../../store/login";
import {
  ChartTranslation,
  PerfisUsuarios,
  PhChartOptions,
  DiaConfig
} from "../../../services/consts";

library.add(faPencilRuler, faTractor, faThermometer);
window.moment = moment;
mtz();
HC_exporting(Highcharts);
Highcharts.setOptions({ ...ChartTranslation, ...PhChartOptions });

export default function GraficoPH(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDateTimeInicialOpen, setIsDateTimeInicialOpen] = useState(false);
  const [isDateTimeFinalOpen, setIsDateTimeFinalOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [estrutura, setEstrutura] = useState([]);
  const [dados, setDados] = useState({ series: [] });
  const [dataInicial, setDataInicial] = useState(
    moment()
      .subtract(1, "weeks")
      .toDate()
  );
  const [dataFinal, setDataFinal] = useState(moment().toDate());
  const cliente = useStore(ClientesStore.clienteSelecionado);
  const usuarioLogado = useStore(LoginStore.usuarioLogado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, []);

  const getEstrutura = useCallback(async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`graficos/estrutura/${cliente.id}`);
      let i = 0;
      const nodesAux = req.data.map(fazenda => {
        // fazendas
        i++;
        const f = {
          id: i,
          icon: <FontAwesomeIcon icon="tractor" />,
          nodeData: { id: fazenda.id },
          label: fazenda.nome,
          childNodes: fazenda.projetos.map(projeto => {
            // projetos
            i++;
            const p = {
              id: i,
              icon: <FontAwesomeIcon icon="pencil-ruler" />,
              nodeData: { id: projeto.id },
              label: projeto.descricao,
              childNodes: [],
              isExpanded: false
            };
            projeto.setores.forEach(setor => {
              p.childNodes = p.childNodes.concat(
                setor.baterias.map(bateria => {
                  // baterias
                  i++;
                  const b = {
                    id: i,
                    icon: <FontAwesomeIcon icon="thermometer" />,
                    nodeData: {
                      id: bateria.id,
                      setor_id: bateria.setor_id,
                      isSelected: false
                    },
                    secondaryLabel: (
                      <Icon icon="cross" iconSize={20} intent="danger" />
                    ),
                    label: bateria.descricao
                  };
                  return b;
                })
              );
            });

            return p;
          }),
          isExpanded: false
        };
        return f;
      });
      setEstrutura(nodesAux);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [cliente.id, presentDialog])

  useEffect(() => {
    if (usuarioLogado.id) {
      if (
        usuarioLogado.perfil === PerfisUsuarios.ADMIN ||
        usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR ||
        usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA ||
        usuarioLogado.perfil === PerfisUsuarios.CLIENTE ||
        usuarioLogado.perfil === PerfisUsuarios.IRRIGADOR
      ) {
        if (cliente.id) {
          setDados({ series: [] });
          getEstrutura();
        }
      } else props.history.push("/dashboard");
    } else props.history.push("/");
  }, [cliente.id, props.history, usuarioLogado, presentDialog, getEstrutura]);

  const onNodeCollapse = useCallback((node, nodePath) => {
    const estruturaAux = [...estrutura];
    const level = nodePath.length;

    if (level === 1) estruturaAux[nodePath[0]].isExpanded = false;
    else if (level === 2)
      estruturaAux[nodePath[0]].childNodes[nodePath[1]].isExpanded = false;

    setEstrutura(estruturaAux);
  }, [estrutura]);

  const onNodeExpand = useCallback((node, nodePath) => {
    const estruturaAux = [...estrutura];
    const level = nodePath.length;

    if (level === 1) estruturaAux[nodePath[0]].isExpanded = true;
    else if (level === 2)
      estruturaAux[nodePath[0]].childNodes[nodePath[1]].isExpanded = true;

    setEstrutura(estruturaAux);
  }, [estrutura]);

  const onNodeClick = useCallback((node, nodePath) => {
    const level = nodePath.length;

    if (level === 3) {
      const estruturaAux = [...estrutura];
      const fazenda = estruturaAux[nodePath[0]];
      const projeto = fazenda.childNodes[nodePath[1]];
      let bateria = projeto.childNodes[nodePath[2]];

      bateria.nodeData.isSelected = !bateria.nodeData.isSelected;
      bateria.secondaryLabel = (
        <Icon
          icon={bateria.nodeData.isSelected ? "tick" : "cross"}
          iconSize={20}
          intent={bateria.nodeData.isSelected ? "success" : "danger"}
        />
      );

      setEstrutura(estruturaAux);
    }
  }, [estrutura]);

  const buscarDados = useCallback(async () => {
    try {
      setIsLoading(true);
      const bateriasSelecionadas = [];
      estrutura.forEach(fazenda =>
        fazenda.childNodes.forEach(projeto =>
          projeto.childNodes.forEach(bateria => {
            if (bateria.nodeData.isSelected)
              bateriasSelecionadas.push(bateria.nodeData);
          })
        )
      );

      const response = await Api.postRequest("graficos/ph", {
        dataInicial: moment(dataInicial).format("YYYY-MM-DD"),
        dataFinal: moment(dataFinal).format("YYYY-MM-DD"),
        baterias: bateriasSelecionadas
      });
      setIsLoading(false);
      setDados(response.data);
      if (dados.series.length) setIsModalOpen(true)
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [estrutura, dataInicial, dataFinal, presentDialog]);

  return (
    <Container>
      <NavBar history={props.history} />
      {isLoading ? (
        <Spinner intent="primary" size={70} />
      ) : (
        <>
          <Arvore
            contents={estrutura}
            onNodeCollapse={(node, nodePath) => onNodeCollapse(node, nodePath)}
            onNodeExpand={(node, nodePath) => onNodeExpand(node, nodePath)}
            onNodeClick={(node, nodePath) => onNodeClick(node, nodePath)}
          />
          <OpcoesGrafico>
            <Opcoes>
              <Button
                large
                icon="search"
                text="Buscar"
                intent="success"
                onClick={buscarDados}
                disabled={!estrutura.length}
              />
              <DateTime
                padding={0}
                label={`Data Inicial: ${moment(dataInicial).format('DD/MM/YYYY')}`}
                icon="calendar"
                onClickButton={() => setIsDateTimeInicialOpen(true)}
                isOpen={isDateTimeInicialOpen}
                headerFormat="DD/MM/YYYY"
                showCaption
                dateConfig={DiaConfig}
                value={dataInicial}
                onSelect={dataSelecionada => {
                  setDataInicial(dataSelecionada);
                  setIsDateTimeInicialOpen(false);
                }}
                onCancel={() => setIsDateTimeInicialOpen(false)}
              />
              <DateTime
                padding={0}
                label={`Data Final: ${moment(dataFinal).format('DD/MM/YYYY')}`}
                icon="calendar"
                onClickButton={() => setIsDateTimeFinalOpen(true)}
                isOpen={isDateTimeFinalOpen}
                headerFormat="DD/MM/YYYY"
                showCaption
                dateConfig={DiaConfig}
                min={dataInicial}
                value={dataFinal}
                onSelect={dataSelecionada => {
                  setDataFinal(dataSelecionada);
                  setIsDateTimeFinalOpen(false);
                }}
                onCancel={() => setIsDateTimeFinalOpen(false)}
              />
            </Opcoes>
            <ChartModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title='Gráfico de tensiomêtros'>
              <HighchartsReact
                containerProps={styles.chartContainer}
                highcharts={Highcharts}
                options={{
                  ...ChartTranslation,
                  ...PhChartOptions,
                  ...dados
                }}
              />
            </ChartModal>
            {dados.series.length ? (
              <Button
                large
                icon="chart"
                text="Exibir gráfico"
                intent="success"
                onClick={() => setIsModalOpen(true)}
                disabled={!estrutura.length}
              />
            ) : (
              <NonIdealState
                icon="search"
                title="Sem dados"
                description="Não existem dados para serem exibidos. Faça uma busca"
              />
            )}
          </OpcoesGrafico>
        </>
      )}
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
