import CryptoJS from "crypto-js";

import { SecretKey } from "./consts";

const USUARIO_LOGADO_DATABASE = "usuarioLogado";

export const salvarUsuarioLogado = usuario => {
  let encryptObj = CryptoJS.AES.encrypt(
    JSON.stringify(usuario),
    SecretKey
  ).toString();
  localStorage.setItem(USUARIO_LOGADO_DATABASE, encryptObj);
  return usuario;
};

export const excluirDados = () => {
  localStorage.removeItem(USUARIO_LOGADO_DATABASE);
  return {};
};

export const getUsuarioLogado = () => {
  let encryptStr = localStorage.getItem(USUARIO_LOGADO_DATABASE);

  if (encryptStr) {
    let bytes = CryptoJS.AES.decrypt(encryptStr, SecretKey);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return user;
  }

  return {};
};
