import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  /* margin: 20px 10px 0 0; */
`;

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  label {
    /* width: 30%; */
    /* margin: 0 10px 0 10px; */
    font-size: 16px;
    font-weight: bold;
  }
`;

export const InputElement = styled.input`
  width: 100%;
`;

export const TextAreaElement = styled.textarea`
  width: 100%;
  height: ${props => props.height};
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;
