import styled from "styled-components";

import {
  Card as blueprintjsCard,
  InputGroup as blueprintjsInput
} from "@blueprintjs/core";
import Colors from "../../styles/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.AZUL_HYDRA};
`;

export const Card = styled(blueprintjsCard)`
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 400px;
    height: auto;
    margin: 10px 0 60px 0;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 700px) {
    width: 300px;
    height: 400px;

    img {
      width: 200px;
      height: auto;
      margin: 10px 0 40px 0;
    }
  }
`;

export const Input = styled(blueprintjsInput)`
  width: 400px;
  margin: 0 0 10px 0;

  @media (max-width: 700px) {
    width: 200px;
  }
`;

export const Actions = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 700px) {
    width: 200px;
    flex-direction: column;

    button {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
`;
