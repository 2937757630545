import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Clientes from "../pages/Clientes";
import Fazendas from "../pages/Fazendas";
import Projetos from "../pages/Projetos";
import Baterias from "../pages/Baterias";
import Usuarios from "../pages/Usuarios";
import Leituras from "../pages/Leituras";
import Irrigacoes from "../pages/Irrigacoes";
import Dicas from "../pages/Dicas";
import GraficosTensiometros from "../pages/Graficos/Tensiometros";
import GraficosExtratores from "../pages/Graficos/Extratores";
import GraficosPh from "../pages/Graficos/PH";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/clientes" component={Clientes} />
      <Route exact path="/fazendas" component={Fazendas} />
      <Route exact path="/projetos" component={Projetos} />
      <Route exact path="/baterias" component={Baterias} />
      <Route exact path="/usuarios" component={Usuarios} />
      <Route exact path="/leituras" component={Leituras} />
      <Route exact path="/irrigacoes" component={Irrigacoes} />
      <Route exact path="/dicas" component={Dicas} />
      <Route
        exact
        path="/graficos/tensiometros"
        component={GraficosTensiometros}
      />
      <Route exact path="/graficos/extratores" component={GraficosExtratores} />
      <Route exact path="/graficos/ph" component={GraficosPh} />
      <Route component={Login} />
    </Switch>
  );
}
