import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button, Icon } from "@blueprintjs/core";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Select from "../../components/Select";
import NumberInput from "../../components/NumberInput";
import Input from "../../components/Input";
import Dialog from "../../components/Dialog";

import {
  Container,
  Form,
  CardContent,
  CardBody,
  Setores,
  AdicionarSetores,
  Setor,
  SetorContent,
  FormSetor
} from "./styles";

import ClienteStore from "../../store/clientes";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import { PerfisUsuarios } from "../../services/consts";

export default function Projetos(props) {
  const colunas = [
    { headerName: "Descrição", field: "descricao" },
    { headerName: "Fazenda", field: "fazenda.nome" },
    { headerName: "Setores", field: "setores.length" }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [id, setId] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [fazenda, setFazenda] = useState({ cliente: {} });
  const [setores, setSetores] = useState([]);
  const [listaFazendas, setListaFazendas] = useState([]);
  const [listaProjetos, setListaProjetos] = useState([]);
  const usuarioLogado = useStore(LoginStore.usuarioLogado);
  const cliente = useStore(ClienteStore.clienteSelecionado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])

  const getProjetos = useCallback(async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`projetos/${cliente.id}`);
      setListaProjetos(req.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [cliente.id, presentDialog])
  
  useEffect(() => {
    if (usuarioLogado.id) {
      if (usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA) {
        if (cliente.id) getProjetos();
      } else props.history.push("/dashboard");
    } else props.history.push("/");
  }, [cliente.id, getProjetos, props.history, usuarioLogado]);

  function tableActions() {
    return (
      <Button
        large
        intent="success"
        icon="plus"
        type="button"
        text="Novo projeto"
        onClick={async () => {
          try {
            await getFazendas();
            setId(0);
            setDescricao("");
            setFazenda({});
            setSetores([]);
            setIsModalOpen(true);
          } catch (err) {
            console.log(err);
          }
        }}
        disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
      />
    );
  }

  const getFazendas = useCallback(async () => {
    try {
      if (!listaFazendas.length) {
        setIsLoading(true);
        const reqFazendas = await Api.getRequest(`fazendas/${cliente.id}`);
        setListaFazendas(reqFazendas.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
        }
      }
      throw err;
    }
  }, [cliente.id, listaFazendas.length, presentDialog])

  const onRowDoubleClicked = useCallback((item) => {
    try {
      const { id, descricao, fazenda, setores } = item.data;
      setId(id);
      setDescricao(descricao);
      setFazenda(fazenda);
      setSetores(setores);
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }, [])

  function cardContent(projeto) {
    return (
      <CardContent>
        <CardBody>
          <h4>{projeto.descricao}</h4>
          <br />
          <span>Fazenda: {projeto.fazenda.nome}</span>
          <span>Setores: {projeto.setores.length}</span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: projeto })}
          />
        </div>
      </CardContent>
    );
  }

  const handleOpenSetor = useCallback((setor) => {
    setSetores(
      setores.map(s =>
        s.id === setor.id
          ? { ...s, isOpen: !setor.isOpen }
          : { ...s, isOpen: false }
      )
    );
  }, [setores])

  const handleAddSetor = useCallback(() => {
    const setoresAux = [...setores];
    const proximo = setores.length + 1;
    const s = {
      id: Math.random(),
      isOpen: false,
      descricao: `Setor ${proximo}`
    };
    if (proximo === 1) setoresAux.push(s);
    else {
      const setorAnterior = { ...setores[setores.length - 1] };
      setoresAux.push({ ...setorAnterior, ...s });
    }
    setSetores(setoresAux);
  }, [setores])

  const handleRemoveSetor = useCallback((setor) => {
    const setoresAux = [...setores];
    const index = setoresAux.findIndex(s => s.id === setor.id);
    if (index > -1) {
      setoresAux.splice(index, 1);
      setSetores(setoresAux);
    }
  }, [setores])

  function setoresRender() {
    return (
      <Setores>
        <AdicionarSetores>
          <h4>Setores</h4>
          <Button
            icon="plus"
            intent="primary" 
            onClick={handleAddSetor} 
            disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA} 
          />
        </AdicionarSetores>
        {setores.map(s => (
          <Fragment key={s.id}>
            <Setor interactive elevation={0} onClick={() => handleOpenSetor(s)}>
              {s.descricao}
              <Icon icon={s.isOpen ? "chevron-down" : "chevron-left"} />
            </Setor>
            <SetorContent isOpen={s.isOpen}>
              <Button
                icon="trash"
                intent="danger"
                text="Remover"
                onClick={() => handleRemoveSetor(s)}
                disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
              />
              <FormSetor>
                <Input
                  label="Descrição"
                  inline
                  autoComplete="descricao"
                  placeholder="descrição do setor"
                  value={s.descricao}
                  onChange={evt =>
                    handleChangeSetor(s, "descricao", evt.currentTarget.value)
                  }
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
                <NumberInput
                  label="Área (ha)"
                  placeholder="área do setor (ha)"
                  value={s.area}
                  decimal
                  onChange={value => handleChangeSetor(s, "area", value)}
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
                <NumberInput
                  label="Esp. Linhas (m)"
                  placeholder="esp. linhas (m)"
                  value={s.espLinhas}
                  decimal
                  onChange={value => handleChangeSetor(s, "espLinhas", value)}
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
                <NumberInput
                  label="Esp. Plantas (m)"
                  placeholder="esp. plantas (m)"
                  value={s.espPlantas}
                  decimal
                  onChange={value => handleChangeSetor(s, "espPlantas", value)}
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
                <NumberInput
                  label="Esp. Linhas Emissores (m)"
                  placeholder="esp. linhas emissores (m)"
                  value={s.espLinhasEmissores}
                  decimal
                  onChange={value =>
                    handleChangeSetor(s, "espLinhasEmissores", value)
                  }
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
                <NumberInput
                  label="Esp. Emissores (m)"
                  placeholder="esp. emissores (m)"
                  value={s.espEmissores}
                  decimal
                  onChange={value =>
                    handleChangeSetor(s, "espEmissores", value)
                  }
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
                <NumberInput
                  label="Vazão Emissor (l/h)"
                  placeholder="vazão emissor (l/h)"
                  value={s.vazaoEmissor}
                  decimal
                  onChange={value =>
                    handleChangeSetor(s, "vazaoEmissor", value)
                  }
                  disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                />
              </FormSetor>
            </SetorContent>
          </Fragment>
        ))}
      </Setores>
    );
  }

  const handleChangeSetor = useCallback((setor, field, newValue) => {
    setSetores(
      setores.map(s => (s.id === setor.id ? { ...s, [field]: newValue } : s))
    );
  }, [setores])

  function modalContent() {
    return (
      <Form>
        <Select
          label="Fazenda"
          items={listaFazendas}
          textValue={item => item.nome}
          itemKey="id"
          selected={fazenda}
          onSelect={selected => setFazenda(selected)}
          disabled={id || !cliente.id}
        />
        <Input
          label="Descrição"
          autoComplete="descricao"
          placeholder="descrição do projeto"
          value={descricao}
          onChange={evt => setDescricao(evt.currentTarget.value)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        {setoresRender()}
      </Form>
    );
  }

  function modalActions() {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        onClick={() => setIsConfirmationOpen(true)}
        disabled={!canSave()}
      />
    );
  }

  const canSave = useCallback(() => {
    let setoresOK = !!setores.length;
    setores.forEach(s => {
      if (
        s.descricao === null ||
        s.descricao === undefined ||
        s.area === null ||
        s.area === undefined ||
        s.espLinhas === null ||
        s.espLinhas === undefined ||
        s.espPlantas === null ||
        s.espPlantas === undefined ||
        s.espLinhasEmissores === null ||
        s.espLinhasEmissores === undefined ||
        s.espEmissores === null ||
        s.espEmissores === undefined ||
        s.vazaoEmissor === null ||
        s.vazaoEmissor === undefined
      )
        setoresOK = false;
    });

    return fazenda.id && descricao && setoresOK && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA;
  }, [descricao, fazenda.id, setores, usuarioLogado])

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        descricao,
        setores,
        fazenda
      };
      if (!id) {
        await Api.postRequest("projetos", dados);
      } else {
        await Api.putRequest("projetos", { ...dados, id });
      }
      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Projeto salvo com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          setErrorCode(err.response.status);
          presentDialog(
            "Erro",
            `Ocorreu um erro ao salvar os dados. Erro: ${err.message}`
          );
        }
      }
    }
  }, [descricao, fazenda, id, presentDialog, setores])

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaProjetos}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes do projeto"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />
      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
