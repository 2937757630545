import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding !== null ? props.padding : '20px 0 0 0'};

  label {
    padding: 0 10px 0 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
`;
