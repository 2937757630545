import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button } from "@blueprintjs/core";
import moment from "moment";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import PhoneInput from "../../components/PhoneInput";
import Dialog from "../../components/Dialog";
import Input from "../../components/Input";
import DateTime from "../../components/DateTime";
import {
  Container,
  Form,
  CardContent,
  CardBody
} from "./styles";
import ClientesStore from "../../store/clientes";
import { PerfisUsuarios, DiaConfig } from "../../services/consts";
import Api from "../../services/api";
import LoginStore from "../../store/login";

export default function Clientes(props) {
  const colunas = [
    { headerName: "Nome", field: "nome" },
    { headerName: "Telefone", field: "telefone" },
    { headerName: "E-mail", field: "email" },
    {
      headerName: "Data limite de acesso",
      field: "dataLimite",
      valueFormatter: params => moment(params.value).format("L")
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isDateTimeOpen, setIsDateTimeOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [dataLimite, setDataLimite] = useState(moment().toDate());
  const clientes = useStore(ClientesStore.lista);

  const usuarioLogado = useStore(LoginStore.usuarioLogado);

  useEffect(() => {
    if (usuarioLogado.id) {
      if (usuarioLogado.perfil !== PerfisUsuarios.ADMIN && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA) {
        props.history.push("/dashboard");
      }
    } else props.history.push("/");
  }, [props.history, usuarioLogado]);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])

  function tableActions() {
    return (
      <Button
        large
        intent="success"
        icon="plus"
        type="button"
        text="Novo cliente"
        onClick={() => {
          setId(0);
          setNome("");
          setTelefone("");
          setEmail("");
          setDataLimite(moment().toDate());
          setIsModalOpen(true);
        }}
        disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
      />
    );
  }

  const onRowDoubleClicked = useCallback((item) => {
    const { id, nome, telefone, email, dataLimite } = item.data;
    setId(id);
    setNome(nome);
    setTelefone(telefone);
    setEmail(email);
    setDataLimite(moment(dataLimite).toDate());
    setIsModalOpen(true);
  }, [])

  function cardContent(cliente) {
    return (
      <CardContent>
        <CardBody>
          <h4>{cliente.nome}</h4>
          <br />
          <span>Telefone: {cliente.telefone}</span>
          <span>E-mail: {cliente.email}</span>
          <span>Acesso até: {moment(cliente.dataLimite).format("L")}</span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: cliente })}
          />
        </div>
      </CardContent>
    );
  }

  function modalContent() {
    return (
      <Form>
        <Input
          label="Nome"
          autoComplete="nome"
          placeholder="nome do cliente"
          value={nome}
          onChange={evt => setNome(evt.currentTarget.value)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />

        <PhoneInput
          autoComplete="telefone"
          label="Telefone"
          placeholder="número de telefone"
          value={telefone}
          onChange={value => setTelefone(value)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        <Input
          label="E-mail"
          autoComplete="email"
          type="email"
          placeholder="e-mail do cliente"
          value={email}
          onChange={evt => setEmail(evt.currentTarget.value)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />

        <DateTime
          label={`Data limite de acesso: ${moment(dataLimite).format('DD/MM/YYYY')}`}
          icon="calendar"
          onClickButton={() => setIsDateTimeOpen(true)}
          isOpen={isDateTimeOpen}
          headerFormat="DD/MM/YYYY"
          showCaption
          dateConfig={DiaConfig}
          max={moment().add(3, 'years').toDate()}
          value={dataLimite}
          onSelect={dataSelecionada => {
            setDataLimite(dataSelecionada);
            setIsDateTimeOpen(false);
          }}
          onCancel={() => setIsDateTimeOpen(false)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
      </Form>
    );
  }

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dataLimiteString = moment(dataLimite)
        .second(0)
        .minute(59)
        .hour(23)
        .toISOString();
      const dados = {
        nome,
        telefone,
        email,
        dataLimite: dataLimiteString
      };
      if (!id) {
        await Api.postRequest("clientes", dados);
      } else {
        await Api.putRequest("clientes", { ...dados, id });
      }

      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Cliente salvo com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          setErrorCode(err.response.status);
          presentDialog(
            "Erro",
            `Ocorreu um erro ao salvar os dados. Erro: ${err.message}`
          );
        }
      }
    }
  }, [dataLimite, email, id, nome, presentDialog, telefone])

  const canSave = useCallback(() => {
    return (
      nome &&
      (telefone.length === 10 || telefone.length === 11) &&
      email &&
      dataLimite &&
      usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA
    );
  }, [dataLimite, email, nome, telefone.length, usuarioLogado])

  function modalActions() {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        disabled={!canSave()}
        onClick={() => setIsConfirmationOpen(true)}
      />
    );
  }

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={clientes}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes do cliente"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />

      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() => {
              setIsSuccessDialogOpen(false);
              if (errorCode === 401) props.history.push("/");
              else props.history.push("/dashboard");
            }}
          />
        }
      />
    </Container>
  );
}
