import styled from "styled-components";

import { InputGroup, Card } from "@blueprintjs/core";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.loading ? "center" : "start")};
`;

export const TableActions = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  width: 90%;

  button {
    width: 170px;
    margin: 0 0 5px 0;
  }

  @media (min-width: 768px) {
    width: 70%;
  }
`;

export const Tabela = styled.div`
  display: none;
  width: 70%;
  min-height: calc(100% - 200px);

  @media (min-width: 768px) {
    display: block;
  }
`;

export const SearchBar = styled(InputGroup)`
  width: 100%;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 99%;
  width: 90%;
  overflow: auto;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 200px;
  width: 99%;
  margin: 5px 0;
`;
