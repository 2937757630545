/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";

import { Alignment, Button, Position, Icon } from "@blueprintjs/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faCloudSunRain,
  faPencilRuler,
  faTractor,
  faUsers,
  faUserTie,
  faThermometer
} from "@fortawesome/free-solid-svg-icons";

import Input from "../Input";
import Dialog from "../Dialog";

import {
  BlueprintjsNavbar,
  DropdownMenu,
  DropdownContent,
  Menu,
  MenuItem,
  Drawer,
  MobileMenuContainer,
  MobileMenuItem,
  Collapse,
  DropdownContentUser,
  SelectCliente,
  SelectBox,
  Password
} from "./styles";
import logoBranca from "../../assets/images/logo-connect-branca.png";
import LoginStore from "../../store/login";
import Api from "../../services/api";
import ClientesStore from "../../store/clientes";
import { PerfisUsuarios } from "../../services/consts";

library.add(
  faFileAlt,
  faCloudSunRain,
  faPencilRuler,
  faTractor,
  faUsers,
  faUserTie,
  faThermometer
);

export default function Navbar(props) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const usuarioLogado = useStore(LoginStore.usuarioLogado);
  const clienteSelecionado = useStore(ClientesStore.clienteSelecionado);
  const clientes = useStore(ClientesStore.lista);
  const loadingClientes = useStore(ClientesStore.loading);
  const errorClientes = useStore(ClientesStore.error);
  const errorClientesMessage = useStore(ClientesStore.errorMessage);
  const errorClientesCode = useStore(ClientesStore.errorCode);

  useEffect(() => {
    function handleMenuOptionClick(path) {
      if (props.history.location.pathname !== path) props.history.push(path);
      else setMenuIsOpen(false);
    }

    async function setOptions() {
      if (!menuOptions.length) {
        setMenuOptions([
          {
            id: 1,
            name: "Registros",
            icon: "database",
            subMenu: [
              {
                id: 11,
                name: "Leituras",
                icon: "clipboard",
                onClick: () => handleMenuOptionClick("/leituras"),
                isAllowed: !!usuarioLogado.id
              },
              {
                id: 12,
                name: "Irrigações",
                icon: "tint",
                onClick: () => handleMenuOptionClick("/irrigacoes"),
                isAllowed: !!usuarioLogado.id
              }
            ],
            isSubMenuOpen: false,
            isAllowed: !!usuarioLogado.id
          },
          {
            id: 2,
            name: "Gráficos",
            icon: "chart",
            subMenu: [
              {
                id: 21,
                name: "Tensiômetros",
                icon: "chart",
                onClick: () => handleMenuOptionClick("/graficos/tensiometros"),
                isAllowed: !!usuarioLogado.id
              },
              {
                id: 22,
                name: "Extratores",
                icon: "chart",
                onClick: () => handleMenuOptionClick("/graficos/extratores"),
                isAllowed: !!usuarioLogado.id

              },
              {
                id: 23,
                name: "PH",
                icon: "chart",
                onClick: () => handleMenuOptionClick("/graficos/ph"),
                isAllowed: !!usuarioLogado.id
              }
            ],
            isSubMenuOpen: false,
            isAllowed: !!usuarioLogado.id
          },
          {
            id: 3,
            name: "Dica do Agrônomo",
            icon: <FontAwesomeIcon icon="file-alt" />,
            onClick: () => handleMenuOptionClick("/dicas"),
            isAllowed:
              usuarioLogado.perfil === PerfisUsuarios.ADMIN ||
              usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR ||
              usuarioLogado.perfil === PerfisUsuarios.CLIENTE
          },
          {
            id: 4,
            name: "Administração",
            icon: "wrench",
            subMenu: [
              {
                id: 12,
                name: "Clientes",
                icon: <FontAwesomeIcon icon="user-tie" />,
                onClick: () => handleMenuOptionClick("/clientes"),
                isAllowed: usuarioLogado.perfil === PerfisUsuarios.ADMIN
              },
              {
                id: 22,
                name: "Usuários",
                icon: <FontAwesomeIcon icon="users" />,
                onClick: () => handleMenuOptionClick("/usuarios"),
                isAllowed: usuarioLogado.perfil === PerfisUsuarios.ADMIN
              },
              {
                id: 32,
                name: "Fazendas",
                icon: <FontAwesomeIcon icon="tractor" />,
                onClick: () => handleMenuOptionClick("/fazendas"),
                isAllowed: usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA
              },
              {
                id: 42,
                name: "Projetos",
                icon: <FontAwesomeIcon icon="pencil-ruler" />,
                onClick: () => handleMenuOptionClick("/projetos"),
                isAllowed: usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA
              },
              {
                id: 52,
                name: "Baterias",
                icon: <FontAwesomeIcon icon="thermometer" />,
                onClick: () => handleMenuOptionClick("/baterias"),
                isAllowed: usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA
              }
            ],
            isSubMenuOpen: false,
            isAllowed: usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA
          }
        ]);
      }
    }

    if ((usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA) && !clientes.length) {
      ClientesStore.get();
    } else if (usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR) {
      ClientesStore.setClienteSelecionado(usuarioLogado.clientes[0]);
      ClientesStore.setLista(usuarioLogado.clientes)
    } else if (usuarioLogado.cliente)
      ClientesStore.setClienteSelecionado(usuarioLogado.cliente);
    setOptions();
  }, [usuarioLogado]);

  function presentDialog(title, message) {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }

  function handleMenuMobileClick(option) {
    if (option.subMenu) {
      const newOptions = menuOptions.map(op =>
        op.id === option.id ? { ...op, isSubMenuOpen: !op.isSubMenuOpen } : op
      );
      setMenuOptions(newOptions);
    } else {
      option.onClick();
    }
  }

  function handleMenuMobileClose() {
    const newOptions = menuOptions.map(op =>
      op.subMenu ? { ...op, isSubMenuOpen: false } : op
    );
    setMenuIsOpen(false);
    setMenuOptions(newOptions);
  }

  function itemRenderer(item, { handleClick, modifiers }) {
    if (!modifiers.matchesPredicate) return null;

    return <MenuItem key={item.nome} onClick={handleClick} text={item.nome} />;
  }

  function filterItem(query, item) {
    return item.nome.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }

  async function changePassword() {
    try {
      setIsLoading(true);
      await Api.postRequest("alterarSenha", { newPassword });
      setIsLoading(false);
      setIsPasswordDialogOpen(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setIsPasswordDialogOpen(false);
      presentDialog("Erro", "Não foi possível alterar a senha");
    }
  }

  function changePasswordDialogContent() {
    return (
      <Password>
        <Input
          type="password"
          label="Nova senha"
          autoComplete="novaSenha"
          placeholder="sua nova senha"
          value={newPassword}
          onChange={evt => setNewPassword(evt.currentTarget.value)}
        />
        <Input
          type="password"
          label="Repita"
          autoComplete="repita"
          placeholder="sua nova senha"
          value={newPasswordAgain}
          onChange={evt => setNewPasswordAgain(evt.currentTarget.value)}
        />
      </Password>
    );
  }

  return (
    <>
      <Dialog
        isOpen={errorClientes}
        onClose={() => ClientesStore.setError(false)}
        title="Erro"
        text={errorClientesMessage}
        actions={
          <Button
            text="OK"
            onClick={() => {
              ClientesStore.setError(false);
              if (errorClientesCode === 401) props.history.push("/");
              else props.history.push("/dashboard");
            }}
          />
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button text="OK" onClick={() => setIsSuccessDialogOpen(false)} />
        }
      />
      <Dialog
        isOpen={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
        title="Alterar senha?"
        text={changePasswordDialogContent()}
        actions={
          <>
            <Button
              icon="tick"
              text="Alterar"
              intent="success"
              onClick={changePassword}
              disabled={
                !newPassword ||
                !newPasswordAgain ||
                newPassword !== newPasswordAgain
              }
              loading={isLoading}
            />
            <Button
              icon="cross"
              text="Cancelar"
              intent="danger"
              onClick={() => setIsPasswordDialogOpen(false)}
            />
          </>
        }
      />

      <BlueprintjsNavbar fixedToTop mobile={1}>
        <BlueprintjsNavbar.Group align={Alignment.LEFT}>
          <Button
            minimal
            large
            icon="menu"
            onClick={() => setMenuIsOpen(true)}
          />
          <Link to="/dashboard">
            <img src={logoBranca} alt="logo" />
          </Link>
        </BlueprintjsNavbar.Group>
        <BlueprintjsNavbar.Group align={Alignment.RIGHT}>
          <span>Bem vindo, {usuarioLogado.nome}</span>
          <DropdownMenu>
            <Button minimal icon="user" />
            <DropdownContentUser>
              <Menu>
                <MenuItem
                  icon="lock"
                  text="Alterar senha"
                  onClick={() => {
                    setNewPassword("");
                    setNewPasswordAgain("");
                    setIsPasswordDialogOpen(true);
                  }}
                />
                <MenuItem
                  icon="log-out"
                  text="Sair"
                  onClick={() => {
                    LoginStore.logOut();
                    props.history.push("/");
                  }}
                />
              </Menu>
            </DropdownContentUser>
          </DropdownMenu>
        </BlueprintjsNavbar.Group>
      </BlueprintjsNavbar>
      <BlueprintjsNavbar fixedToTop>
        <BlueprintjsNavbar.Group align={Alignment.LEFT}>
          <Link to="/dashboard">
            <img src={logoBranca} alt="logo" />
          </Link>
          <BlueprintjsNavbar.Divider id="divider" />
          {menuOptions
            .filter(op => op.isAllowed)
            .map(op =>
              op.subMenu ? (
                <DropdownMenu key={op.id}>
                  <Button
                    minimal
                    icon={op.icon}
                    text={op.name}
                    onClick={op.onClick}
                  />
                  <DropdownContent>
                    <Menu>
                      {op.subMenu
                        .filter(subOp => subOp.isAllowed)
                        .map(subOp => (
                          <MenuItem
                            key={subOp.id}
                            icon={subOp.icon}
                            text={subOp.name}
                            onClick={subOp.onClick}
                          />
                        ))}
                    </Menu>
                  </DropdownContent>
                </DropdownMenu>
              ) : (
                <Button
                  key={op.id}
                  minimal
                  icon={op.icon}
                  text={op.name}
                  onClick={op.onClick}
                />
              )
            )}
        </BlueprintjsNavbar.Group>
        <BlueprintjsNavbar.Group align={Alignment.RIGHT}>
          {usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA ? (
            <SelectCliente
              items={clientes}
              itemRenderer={itemRenderer}
              onItemSelect={selected =>
                ClientesStore.setClienteSelecionado(selected)
              }
              itemPredicate={filterItem}
              noResults={<MenuItem disabled text="Sem resultados." />}
              resetOnClose
              resetOnSelect
              inputProps={{ placeholder: "Pesquisar" }}
              disabled={!clientes.length}
            >
              <SelectBox
                intent="primary"
                rightIcon="caret-down"
                text={clienteSelecionado.nome}
                loading={loadingClientes}
                disabled={!clientes.length}
              />
            </SelectCliente>
          ) : null}
          <span>Bem vindo, {usuarioLogado.nome}</span>
          <DropdownMenu>
            <Button minimal icon="user" />
            <DropdownContentUser>
              <Menu>
                <MenuItem
                  icon="lock"
                  text="Alterar senha"
                  onClick={() => {
                    setNewPassword("");
                    setNewPasswordAgain("");
                    setIsPasswordDialogOpen(true);
                  }}
                />
                <MenuItem
                  icon="log-out"
                  text="Sair"
                  onClick={() => {
                    LoginStore.logOut();
                    props.history.push("/");
                  }}
                />
              </Menu>
            </DropdownContentUser>
          </DropdownMenu>
        </BlueprintjsNavbar.Group>
      </BlueprintjsNavbar>
      <Drawer
        isOpen={menuIsOpen}
        onClose={handleMenuMobileClose}
        usePortal={false}
        position={Position.LEFT}
        size="65%"
        isCloseButtonShown={false}
        title="MENU"
      >
        <MobileMenuContainer>
          {menuOptions
            .filter(op => op.isAllowed)
            .map(op => (
              <Fragment key={op.id}>
                <MobileMenuItem
                  interactive={true}
                  elevation={0}
                  onClick={() => handleMenuMobileClick(op)}
                >
                  <Icon icon={op.icon} />
                  {op.name}
                  {op.subMenu && (
                    <Icon
                      icon={op.isSubMenuOpen ? "chevron-down" : "chevron-left"}
                    />
                  )}
                </MobileMenuItem>
                {op.subMenu && (
                  <Collapse isOpen={op.isSubMenuOpen}>
                    {op.subMenu
                      .filter(subOp => subOp.isAllowed)
                      .map(subOp => (
                        <MobileMenuItem
                          key={subOp.id}
                          interactive={true}
                          elevation={0}
                          onClick={subOp.onClick}
                        >
                          <Icon icon={subOp.icon} />
                          {subOp.name}
                        </MobileMenuItem>
                      ))}
                  </Collapse>
                )}
              </Fragment>
            ))}
          {usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA ? (
            <SelectCliente
              mobile={1}
              items={clientes}
              itemRenderer={itemRenderer}
              onItemSelect={selected =>
                ClientesStore.setClienteSelecionado(selected)
              }
              itemPredicate={filterItem}
              noResults={<MenuItem disabled text="Sem resultados." />}
              resetOnClose
              resetOnSelect
              inputProps={{ placeholder: "Pesquisar" }}
              disabled={!clientes.length}
            >
              <SelectBox
                intent="primary"
                rightIcon="caret-down"
                text={clienteSelecionado.nome}
                loading={loadingClientes}
                disabled={!clientes.length}
              />
            </SelectCliente>
          ) : null}
        </MobileMenuContainer>
      </Drawer>
    </>
  );
}
