import React from "react";

import Input from '../Input';

export default function PhoneInput(props) {
  function format(value) {
    if (value !== null && value !== undefined && value !== "") {
      const fullStr = value.replace(/\D/g, "");
      if (fullStr.length === 1 || fullStr.length === 2) {
        return ["(", fullStr].join("");
      } else if (fullStr.length === 3) {
        return [
          "(",
          fullStr.substring(0, 2),
          ") ",
          fullStr.substring(2, 3)
        ].join("");
      } else if (
        fullStr.length === 4 ||
        fullStr.length === 5 ||
        fullStr.length === 6 ||
        fullStr.length === 7
      ) {
        return [
          "(",
          fullStr.substring(0, 2),
          ") ",
          fullStr.substring(2, fullStr.length)
        ].join("");
      } else if (
        fullStr.length === 8 ||
        fullStr.length === 9 ||
        fullStr.length === 10
      ) {
        return [
          "(",
          fullStr.substring(0, 2),
          ") ",
          fullStr.substring(2, 6),
          "-",
          fullStr.substring(6, fullStr.length)
        ].join("");
      } else if (fullStr.length >= 11) {
        return [
          "(",
          fullStr.substring(0, 2),
          ") ",
          fullStr.substring(2, 7),
          "-",
          fullStr.substring(7, 11)
        ].join("");
      } else return "";
    } else return "";
  }

  function handleOnchange(value) {
    if (value) {
      const fullStr = value.replace(/\D/g, "");
      props.onChange(fullStr);
    }
  }

  return (
    <Input
      {...props}
      label={props.label}
      autoComplete={props.autoComplete}
      type="tel"
      placeholder={props.placeholder}
      value={format(props.value)}
      onChange={evt => handleOnchange(evt.currentTarget.value)}
    />
  );
}
