import styled from "styled-components";
import { Button } from "@blueprintjs/core";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  /* margin: 20px 10px 0 0; */

  .bp3-multi-select .bp3-tag-input-values {
    width: 100%;
    max-width: 100%;
  }
  .bp3-popover-wrapper {
    width: 100%;
  }
  .bp3-popover-target {
    width: 100%;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  label {
    /* width: 30%; */
    /* margin: 0 10px 0 10px; */
    font-size: 16px;
    font-weight: bold;
  }
`;

export const SelectBox = styled(Button)`
  width: 100%;
  height: 30px;
`;
