import styled from "styled-components";
import { Card, Collapse, Switch } from "@blueprintjs/core";
import Colors from "../../styles/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0 0 0 5px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const FormSetor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const Setores = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

export const Setor = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;

  button {
    margin: 0 5px 0 0;
  }
`;

export const SetorContent = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .bp3-collapse-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
`;

export const IrrigarFert = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
`;

export const Informar = styled(Switch)`
  margin: 0 0 0 10px;
`;

export const TituloSecao = styled.div`
  display: flex;
  margin: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.AZUL_HYDRA};
  color: #fff;
  box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.75);

  h4 {
    margin: 0;
    width: auto;
  }
`

export const Opcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    button {
      margin: 0 0 10px 0;
    }
  }
`;
