import React, { useEffect } from "react";
import { useStore } from "effector-react";

import Navbar from "../../components/Navbar";

import { Container, Mensagem, Subtitulo } from "./styles";
import { version } from "../../../package.json";

import LoginStore from "../../store/login";

export default function Dashboard(props) {
  const usuarioLogado = useStore(LoginStore.usuarioLogado);

  useEffect(() => {
    if (!usuarioLogado.id) props.history.push("/");
  }, [props.history, usuarioLogado.id]);

  return (
    <Container>
      <Navbar history={props.history} />
      <Mensagem>Tecnologia</Mensagem>
      <Subtitulo>Aliada à produtividade</Subtitulo>
      <span id="versao">{`Versão: ${version}`}</span>
      
    </Container>
  );
}
