import React from "react";

import { ModalContainer, ModalContent, ModalActions } from "./styles";

export default function Modal(props) {
  return (
    <ModalContainer
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      usePortal={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
    >
      <ModalContent>
        <span>{props.children}</span>
      </ModalContent>
      <ModalActions>{props.actions}</ModalActions>
    </ModalContainer>
  )
}
