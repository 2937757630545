import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";

export const ModalContainer = styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden !important;

  @media (min-width: 700px) {
    width: 700px;
    position: relative;
    height: calc(100vh - 20px);
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 5px 0 0 0;

  button {
    margin: 0 20px 0 0;
  }
`
