import styled from "styled-components";
import dashboardImage from '../../assets/images/dashboard.jpg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${dashboardImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  #versao {
    position: fixed;
    bottom: 5px;
    right: 5px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
  }
`;

export const Mensagem = styled.h1`
  color: #fff;
  font-size: 50px;
  text-transform: uppercase;
`;

export const Subtitulo = styled.h4`
  color: #fff;
  font-size: 15px;
  margin-top: -30px;
  text-transform: uppercase;
`;
