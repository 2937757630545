import React from "react";

import { Container, LabelContainer, InputElement, TextAreaElement } from "./styles";

export default function Input(props) {
  return (
    <Container>
      <LabelContainer>
        <label>{props.label}</label>
        {props.labelActions}
      </LabelContainer>
      {!props.textArea ? (
        <InputElement
          className="bp3-input"
          type={props.type}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          readOnly={props.readOnly}
          disabled={props.disabled}
        />
      ) : (
        <TextAreaElement
          type={props.type}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
          readOnly={props.readOnly}
          height={props.height}
          disabled={props.disabled}
        />
      )}
    </Container>
  );
}
