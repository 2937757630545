import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { Select as BlueprintjsSelect, MultiSelect } from "@blueprintjs/select";

import { Container, SelectBox, LabelContainer } from "./styles";

export default function Select(props) {
  function filterItem(query, item) {
    return (
      props
        .textValue(item)
        .toLowerCase()
        .indexOf(query.toLowerCase()) >= 0
    );
  }

  function itemRenderer(item, { handleClick, modifiers }) {
    if (!modifiers.matchesPredicate) return null;

    return (
      <MenuItem
        label={props.labelValue ? props.labelValue(item) : null}
        key={item[props.itemKey]}
        onClick={handleClick}
        text={props.textValue(item)}
      />
    );
  }

  return (
    <Container>
      <LabelContainer>
        <label>{props.label}</label>
      </LabelContainer>
      {!props.multi ? (
        <BlueprintjsSelect
          items={props.items}
          filterable={!props.notFilter}
          itemRenderer={itemRenderer}
          onItemSelect={props.onSelect}
          itemPredicate={filterItem}
          noResults={<MenuItem disabled text="Sem resultados." />}
          resetOnClose
          resetOnSelect
          inputProps={{ placeholder: "Pesquisar", autoFocus: false }}
          popoverProps={{ autoFocus: false }}
          disabled={props.disabled}
        >
          <SelectBox
            rightIcon="caret-down"
            icon={props.selected.icon}
            text={props.textValue(props.selected)}
            disabled={props.disabled}
          />
        </BlueprintjsSelect>
      ) : (
        <MultiSelect
          items={props.items}
          tagRenderer={item => props.textValue(item)}
          itemRenderer={itemRenderer}
          onItemSelect={props.onSelect}
          itemPredicate={filterItem}
          noResults={<MenuItem disabled text="Sem resultados." />}
          resetOnClose
          resetOnSelect
          selectedItems={props.selected}
          tagInputProps={{
            placeholder: "Selecionar",
            onRemove: props.onRemoveItem,
            disabled: props.disabled
          }}
          popoverProps={{ autoFocus: false }}
        />
      )}
    </Container>
  );
}
