import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button, Switch } from "@blueprintjs/core";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Dialog from "../../components/Dialog";
import NumberInput from "../../components/NumberInput";

import {
  Container,
  Form,
  CardContent,
  CardBody,
  FormSensores,
  AdicionarSensor,
  Profundidades
} from "./styles";

import ClienteStore from "../../store/clientes";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import { PerfisUsuarios } from "../../services/consts";

const colunas = [
  { headerName: "Descrição", field: "descricao" },
  { headerName: "Projeto", field: "setor.projeto.descricao" },
  { headerName: "Setor", field: "setor.descricao" },
  { headerName: "Tensiômetros", field: "tensiometros.length" },
  { headerName: "Extratores", field: "extratores.length" },
  {
    headerName: "Pluviômetro",
    field: "pluviometro",
    valueFormatter: params => (params.value ? "Sim" : "Não")
  },
  {
    headerName: "Ativada",
    field: "ativa",
    valueFormatter: params => (params.value ? "Sim" : "Não")
  }
];

export default function Baterias(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [id, setId] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [projeto, setProjeto] = useState({});
  const [setor, setSetor] = useState({});
  const [tensiometros, setTensiometros] = useState([]);
  const [extratores, setExtratores] = useState([]);
  const [pluviometro, setPluviometro] = useState(false);
  const [ativa, setAtiva] = useState(true);
  const [listaProjetos, setListaProjetos] = useState([]);
  const [listaBaterias, setListaBaterias] = useState([]);
  const usuarioLogado = useStore(LoginStore.usuarioLogado);
  const cliente = useStore(ClienteStore.clienteSelecionado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, []);

  const getBaterias = useCallback(async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`baterias/${cliente.id}`);
      setListaBaterias(req.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [cliente.id, presentDialog])

  useEffect(() => {
    if (usuarioLogado.id) {
      if (usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA) {
        if (cliente.id) getBaterias();
      } else props.history.push("/dashboard");
    } else props.history.push("/");
  }, [cliente.id, props.history, usuarioLogado, presentDialog, getBaterias]);

  const getProjetos = useCallback(async () => {
    try {
      setIsLoading(true);
      const reqProjetos = await Api.getRequest(`projetos/${cliente.id}`);
      setListaProjetos(reqProjetos.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
        setErrorCode(err.response.status);
        presentDialog(
          "Erro",
          `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
        );
      }
      throw err;
    }
  }, [cliente.id, presentDialog]);

  function tableActions () {
    return (
      <Button
        large
        intent="success"
        icon="plus"
        type="button"
        text="Nova bateria"
        onClick={async () => {
          try {
            await getProjetos();
            setId(0);
            setDescricao("");
            setPluviometro(false);
            setAtiva(true);
            setProjeto({});
            setIsModalOpen(true);
          } catch (err) {
            console.log(err);
          }
        }}
        disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
      />
    );
  }

  const onRowDoubleClicked = useCallback(item => {
    try {
      const {
        id,
        descricao,
        setor,
        tensiometros,
        extratores,
        pluviometro,
        ativa
      } = item.data;

      setId(id);
      setDescricao(descricao);
      setPluviometro(pluviometro);
      setAtiva(ativa);
      setProjeto(setor.projeto);
      setSetor(setor);
      setTensiometros(tensiometros);
      setExtratores(extratores);
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  function cardContent (bateria) {
    return (
      <CardContent>
        <CardBody>
          <h4>{bateria.descricao}</h4>
          <br />
          <span>Projeto: {bateria.setor.projeto.descricao}</span>
          <span>Setor: {bateria.setor.descricao}</span>
          <span>Tensiômetros: {bateria.tensiometros.length}</span>
          <span>Extratores: {bateria.extratores.length}</span>
          <span>Ativada: {bateria.ativa ? "Sim" : "Não"}</span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: bateria })}
          />
        </div>
      </CardContent>
    );
  }

  const handleAddTensiometro = useCallback(() => {
    setTensiometros([...tensiometros, { id: Math.random() }]);
  }, [tensiometros]);

  const handleChangeTensiometros = useCallback((tensiometro, valor) => {
    const index = tensiometros.findIndex(t => t.id === tensiometro.id);
    if (index > -1) {
      const tensiometrosAux = [...tensiometros];
      tensiometrosAux[index].profundidade = valor;
      setTensiometros(tensiometrosAux);
    }
  }, [tensiometros]);

  const handleRemoveTensiometros = useCallback(tensiometro => {
    const index = tensiometros.findIndex(t => t.id === tensiometro.id);
    if (index > -1) {
      const tensiometrosAux = [...tensiometros];
      tensiometrosAux.splice(index, 1);
      setTensiometros(tensiometrosAux);
    }
  }, [tensiometros]);

  const handleAddExtratores = useCallback(() => {
    setExtratores([...extratores, { id: Math.random() }]);
  }, [extratores]);

  const handleChangeExtratores = useCallback((extrator, valor) => {
    const index = extratores.findIndex(e => e.id === extrator.id);
    if (index > -1) {
      const extratoresAux = [...extratores];
      extratoresAux[index].profundidade = valor;
      setExtratores(extratoresAux);
    }
  }, [extratores]);

  const handleRemoveExtratores = useCallback(extrator => {
    const index = extratores.findIndex(e => e.id === extrator.id);
    if (index > -1) {
      const extratoresAux = [...extratores];
      extratoresAux.splice(index, 1);
      setExtratores(extratoresAux);
    }
  }, [extratores]);

  function modalContent() {
    return (
      <>
        <Form>
          <Select
            label="Projeto"
            items={listaProjetos}
            textValue={item => item.descricao}
            labelValue={item => item.fazenda.nome}
            itemKey="id"
            selected={projeto}
            onSelect={selected => {
              setProjeto(selected);
              setSetor({});
            }}
            disabled={!cliente.id || id}
          />
          <Select
            label="Setor"
            items={projeto.setores ? projeto.setores : []}
            textValue={item => item.descricao}
            itemKey="id"
            selected={setor}
            onSelect={selected => setSetor(selected)}
            disabled={!projeto.id || id}
          />
          <Input
            label="Descrição"
            autoComplete="descricao"
            placeholder="Descrição da bateria"
            value={descricao}
            onChange={evt => setDescricao(evt.currentTarget.value)}
            disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
          />
        </Form>
        <FormSensores>
          <Switch
            inline
            large
            checked={pluviometro}
            label="Pluviômetro"
            onChange={evt => setPluviometro(evt.currentTarget.checked)}
            disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
          />
          <Switch
            inline
            large
            checked={ativa}
            label="Ativada"
            onChange={evt => setAtiva(evt.currentTarget.checked)}
            disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
          />
          <AdicionarSensor>
            <h4>Tensiômetros</h4>
            <Button
              icon="plus"
              intent="primary"
              onClick={() => handleAddTensiometro()}
              disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
            />
          </AdicionarSensor>
          {tensiometros.map(t => (
            <Profundidades key={t.id}>
              <NumberInput
                label="Profundidade (cm)"
                placeholder="profundidade (cm)"
                value={t.profundidade}
                onChange={value => handleChangeTensiometros(t, value)}
                disabled={t.id >= 1}
              />
              <Button
                minimal
                icon="trash"
                intent="danger"
                onClick={() => handleRemoveTensiometros(t)}
                disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
              />
            </Profundidades>
          ))}
          <AdicionarSensor>
            <h4>Extratores</h4>
            <Button
              icon="plus"
              intent="primary"
              onClick={() => handleAddExtratores()}
              disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
            />
          </AdicionarSensor>
          {extratores.map(ex => (
            <Profundidades key={ex.id}>
              <NumberInput
                label="Profundidade (cm)"
                placeholder="profundidade (cm)"
                value={ex.profundidade}
                onChange={value => handleChangeExtratores(ex, value)}
                disabled={ex.id >= 1}
              />
              <Button
                minimal
                icon="trash"
                intent="danger"
                onClick={() => handleRemoveExtratores(ex)}
                disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
              />
            </Profundidades>
          ))}
        </FormSensores>
      </>
    );
  }

  const canSave = useCallback(() => {
    let tensiometrosOK = true;
    let extratoresOK = true;
    tensiometros.forEach(t => {
      if (t.profundidade === null || t.profundidade === undefined)
        tensiometrosOK = false;
    });
    extratores.forEach(ex => {
      if (ex.profundidade === null || ex.profundidade === undefined)
        tensiometrosOK = false;
    });

    return (
      descricao && projeto.id && setor.id && tensiometrosOK && extratoresOK && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA
    );
  }, [tensiometros, extratores, descricao, projeto, setor, usuarioLogado]);

  function modalActions () {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        disabled={!canSave()}
        onClick={() => setIsConfirmationOpen(true)}
      />
    );
  }

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        descricao,
        pluviometro,
        ativa,
        setor,
        tensiometros,
        extratores
      };
      if (!id) {
        await Api.postRequest("baterias", dados);
      } else {
        await Api.putRequest("baterias", { ...dados, id });
      }

      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Bateria salva com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [descricao, pluviometro, ativa, setor, tensiometros, extratores, id, presentDialog]);

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaBaterias}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes da bateria"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />
      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
