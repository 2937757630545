import styled from "styled-components";
import {
  Navbar,
  Drawer as blueprintjsDrawer,
  Menu as blueprintjsMenu,
  MenuItem as blueprintjsMenuItem,
  Card,
  Collapse as blueprintjsCollapse,
  Button
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import Colors from "../../styles/colors";

export const BlueprintjsNavbar = styled(Navbar)`
  background: ${Colors.AZUL_HYDRA};

  img {
    height: 50px;
    width: auto;
  }

  svg {
    color: #fff;
    width: 16px;
    height: 16px;
  }

  span {
    color: #fff;
  }

  #divider {
    background: #fff;
  }

  @media (max-width: 768px) {
    display: ${props => (props.mobile ? "" : "none")};
  }
`;

export const Menu = styled(blueprintjsMenu)`
  background: ${Colors.AZUL_HYDRA};
`;

export const MenuItem = styled(blueprintjsMenuItem)`
  background: ${Colors.AZUL_HYDRA};
  color: #fff;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  transform: translateX(0px);
`;

export const DropdownContentUser = styled(DropdownContent)`
  transform: translateX(-135px);
`;

export const DropdownMenu = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropdownContent} {
    display: block;
  }
`;

export const Drawer = styled(blueprintjsDrawer)`
  .bp3-drawer-header {
    background: ${Colors.AZUL_HYDRA};
    height: 50px;
  }
  h4 {
    color: #fff;
  }
`;

export const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const MobileMenuItem = styled(Card)`
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;

  svg,
  .svg-inline--fa.fa-w-12 {
    margin: 0 5px 0 0;
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
`;

export const Collapse = styled(blueprintjsCollapse)`
  width: 100%;
  padding: 0 0 0 5px;
`;

export const SelectCliente = styled(Select)`
  margin: ${props => (props.mobile ? "20px 0 0 0" : "0 10px 0 0")};
  z-index: 1;

  @media (max-width: 980px) {
    display: ${props => (props.mobile ? "" : "none")};
  }
`;

export const SelectBox = styled(Button)`
  width: 190px;
  height: 30px;
`;

export const Password = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
