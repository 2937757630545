import React from "react";

import Input from '../Input';

export default function NumberInput(props) {
  function format(value) {
    if (value !== null && value !== undefined && value !== "") {
      if (props.decimal) return value.toFixed(2).replace(".", ",");
      else return value;
    } else return "";
  }

  function handleOnchange(value) {
    const fullStr = value.replace(",", "").replace(/\D/g, "");
    let output = "";
    if (props.decimal) {
      let position = fullStr.length - 2;
      output = "0.00";
      if (fullStr.length === 1) {
        output = ["0.0", fullStr].join("");
      } else {
        output = [
          fullStr.slice(0, position),
          ".",
          fullStr.slice(position)
        ].join("");
      }
    } else output = fullStr;

    props.onChange(Number(output));
  }

  return (
    <Input
      {...props}
      label={props.label}
      autoComplete={props.autoComplete}
      type="tel"
      placeholder={props.placeholder}
      value={format(props.value)}
      onChange={evt => handleOnchange(evt.currentTarget.value)}
    />
  );
}
