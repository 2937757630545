import styled from "styled-components";
import { Tree } from "@blueprintjs/core";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Arvore = styled(Tree)`
  height: 100%;
  padding-top: 70px;
  /* border: 1px solid #000; */

  svg {
    margin-right: 5px;
  }

  @media (max-width: 700px) {
    height: auto;
  }
`;

export const OpcoesGrafico = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    padding: 10px 0 0 0;
    justify-content: flex-start;
  }
`;

export const Opcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 15px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    button {
      margin: 0 0 10px 0;
    }
  }
`;

export const styles = {
  chartContainer: {
    style: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
};
