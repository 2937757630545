import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button } from "@blueprintjs/core";
import moment from "moment";
import * as mtz from "moment-timezone";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Dialog from "../../components/Dialog";
import Input from "../../components/Input";
import DateTime from '../../components/DateTime'

import { Container, Form, CardContent, CardBody, Opcoes } from "./styles";

import ClientesStore from "../../store/clientes";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import { PerfisUsuarios, DiaConfig } from "../../services/consts";

window.moment = moment;
mtz();

export default function Dicas(props) {
  const colunas = [
    {
      headerName: "Data",
      field: "created_at",
      valueFormatter: params => moment(params.value).format("L")
    },
    {
      headerName: "Autor",
      field: "autor.nome"
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [texto, setTexto] = useState("");
  const [listaDicas, setListaDicas] = useState([]);
  const [isDateTimeInicialOpen, setIsDateTimeInicialOpen] = useState(false);
  const [isDateTimeFinalOpen, setIsDateTimeFinalOpen] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment()
      .subtract(1, "weeks")
      .toDate()
  );
  const [dataFinal, setDataFinal] = useState(moment().toDate());
  const cliente = useStore(ClientesStore.clienteSelecionado);
  const usuarioLogado = useStore(LoginStore.usuarioLogado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])
  
  const getDicas = async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`dicas/${cliente.id}?inicio=${moment(dataInicial).format("YYYY-MM-DD")}&final=${moment(dataFinal).format("YYYY-MM-DD")}`);
      setListaDicas(req.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }
  
  useEffect(() => {
    if (usuarioLogado.id) {
      if (
        usuarioLogado.perfil === PerfisUsuarios.ADMIN ||
        usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR ||
        usuarioLogado.perfil === PerfisUsuarios.CLIENTE
      ) {
        if (cliente.id) getDicas();
      } else props.history.push("/dashboard");
    } else props.history.push("/");
  }, [cliente.id, props.history, usuarioLogado]);

  function tableActions() {
    return (
      <Opcoes>
        <Button
          large
          intent="success"
          icon="plus"
          type="button"
          text="Nova Dica"
          onClick={() => {
            setId(0);
            setTexto("");
            setIsModalOpen(true);
          }}
        />
        <Button
          large
          intent="success"
          icon="search"
          type="button"
          text="Buscar"
          onClick={getDicas}
        />
        <DateTime
            padding={0}
            label={`Data Inicial: ${moment(dataInicial).format('DD/MM/YYYY')}`}
            icon="calendar"
            onClickButton={() => setIsDateTimeInicialOpen(true)}
            isOpen={isDateTimeInicialOpen}
            headerFormat="DD/MM/YYYY"
            showCaption
            dateConfig={DiaConfig}
            value={dataInicial}
            onSelect={dataSelecionada => {
              setDataInicial(dataSelecionada);
              setIsDateTimeInicialOpen(false);
            }}
            onCancel={() => setIsDateTimeInicialOpen(false)}
          />
          <DateTime
            padding={0}
            label={`Data Final: ${moment(dataFinal).format('DD/MM/YYYY')}`}
            icon="calendar"
            onClickButton={() => setIsDateTimeFinalOpen(true)}
            isOpen={isDateTimeFinalOpen}
            headerFormat="DD/MM/YYYY"
            showCaption
            dateConfig={DiaConfig}
            min={dataInicial}
            value={dataFinal}
            onSelect={dataSelecionada => {
              setDataFinal(dataSelecionada);
              setIsDateTimeFinalOpen(false);
            }}
            onCancel={() => setIsDateTimeFinalOpen(false)}
          />
        </Opcoes>
    );
  }

  const onRowDoubleClicked = useCallback((item) => {
    const { id, texto } = item.data;
    setId(id);
    setTexto(texto);
    setIsModalOpen(true);
  }, [])

  function cardContent(dica) {
    return (
      <CardContent>
        <CardBody>
          <h4>{moment(dica.created_at).format("L")}</h4>
          <br />
          <span>Autor: {dica.autor.nome}</span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: dica })}
          />
        </div>
      </CardContent>
    );
  }

  function modalContent() {
    return (
      <Form>
        <Input
          textArea
          height="500px"
          label="Dica"
          autoComplete="texto"
          placeholder="dica para o cliente"
          value={texto}
          onChange={evt => setTexto(evt.currentTarget.value)}
          readOnly={usuarioLogado.perfil !== PerfisUsuarios.ADMIN}
        />
      </Form>
    );
  }

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        texto,
        cliente
      };
      if (!id) {
        await Api.postRequest("dicas", dados);
      } else {
        await Api.putRequest("dicas", { ...dados, id });
      }

      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Dica salva com sucesso");
    } catch (err) {
      console.log(err);
      presentDialog(
        "Erro",
        `Ocorreu um erro ao salvar a dica. Erro: ${err.message}`
      );
    }
  }, [cliente, id, presentDialog, texto])

  const canSave = useCallback(() => {
    return texto && cliente.id;
  }, [cliente.id, texto])

  function modalActions() {
    return usuarioLogado.perfil === PerfisUsuarios.ADMIN ? (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        disabled={!canSave()}
        onClick={() => setIsConfirmationOpen(true)}
      />
    ) : null;
  }

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaDicas}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes da dica"
        modalActions={
          usuarioLogado.perfil === PerfisUsuarios.ADMIN ? modalActions() : null
        }
        modalContent={modalContent()}
        loading={isLoading}
      />

      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
