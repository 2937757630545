import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button } from "@blueprintjs/core";
import moment from "moment";
import * as mtz from "moment-timezone";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Select from "../../components/Select";
import Input from "../../components/Input";
import NumberInput from "../../components/NumberInput";
import Dialog from "../../components/Dialog";
import DateTime from "../../components/DateTime";

import {
  Container,
  Form,
  CardContent,
  CardBody,
  Informar,
  TituloSecao,
  Opcoes
} from "./styles";

import ClienteStore from "../../store/clientes";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import { PerfisUsuarios, DiaHoraConfig, DiaConfig } from "../../services/consts";

window.moment = moment;
mtz();

export default function Leituras(props) {
  const colunas = [
    {
      headerName: "Data",
      field: "dataLeitura",
      valueFormatter: params => moment(params.value).format("DD/MM/YYYY HH:mm")
    },
    {
      headerName: "Bateria",
      field: "bateria.descricao"
    },
    {
      headerName: "Observações",
      field: "observacoes"
    },
    {
      headerName: "Pluviômetro (mm)",
      field: "pluviometro",
      valueFormatter: params =>
        params.value ? params.value.toLocaleString() : "-"
    }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDateTimeOpen, setIsDateTimeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [id, setId] = useState(0);
  const [dataLeitura, setDataLeitura] = useState(moment().toDate());
  const [observacoes, setObservacoes] = useState("");
  const [pluviometro, setPluviometro] = useState(undefined);
  const [projeto, setProjeto] = useState({ setores: [] });
  const [bateria, setBateria] = useState({});
  const [tensiometros, setTensiometros] = useState([]);
  const [extratores, setExtratores] = useState([]);
  const [listaProjetos, setListaProjetos] = useState([]);
  const [listaBaterias, setListaBaterias] = useState([]);
  const [listaLeituras, setListaLeituras] = useState([]);
  const [isDateTimeInicialOpen, setIsDateTimeInicialOpen] = useState(false);
  const [isDateTimeFinalOpen, setIsDateTimeFinalOpen] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment()
      .subtract(1, "weeks")
      .toDate()
  );
  const [dataFinal, setDataFinal] = useState(moment().toDate());
  const usuarioLogado = useStore(LoginStore.usuarioLogado);
  const cliente = useStore(ClienteStore.clienteSelecionado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])

  const getLeituras = async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`leituras/${cliente.id}?inicio=${moment(dataInicial).format("YYYY-MM-DD")}&final=${moment(dataFinal).format("YYYY-MM-DD")}`);
      setListaLeituras(req.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }

  useEffect(() => {
    if (usuarioLogado.id) {
      if (
        usuarioLogado.perfil !== PerfisUsuarios.ADMIN && 
        usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR && 
        usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA
      ) {
        if (!listaProjetos.length) {
          const listaProjetosAux = [];
          usuarioLogado.baterias.forEach(b => {
            if (!listaProjetosAux.find(p => p.id === b.projeto.id))
              listaProjetosAux.push(b.projeto);
          });
          setListaProjetos(listaProjetosAux);
          setListaBaterias(usuarioLogado.baterias);
        }
      }
      if (cliente.id) getLeituras();
    } else props.history.push("/");
  }, [cliente.id, listaProjetos.length, presentDialog, props.history, usuarioLogado]);

  const getProjetosBaterias = useCallback(async () => {
    try {
      if (
        usuarioLogado.perfil === PerfisUsuarios.ADMIN ||
        usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR ||
        usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA
      ) {
        setIsLoading(true);
        const reqProjetos = await Api.getRequest(`projetos/${cliente.id}`);
        const reqBaterias = await Api.getRequest(`baterias/${cliente.id}?ativas=true`);
        setListaProjetos(reqProjetos.data);
        setListaBaterias(reqBaterias.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
        }
      }
      throw err;
    }
  }, [cliente.id, presentDialog, usuarioLogado.perfil])

  function tableActions() {
    return (
      <Opcoes>
        <Button
          large
          intent="success"
          icon="plus"
          type="button"
          text="Nova leitura"
          onClick={async () => {
            try {
              await getProjetosBaterias();
              setId(0);
              setDataLeitura(moment().toDate());
              setProjeto({ setores: [] });
              setBateria({});
              setObservacoes("");
              setPluviometro(undefined);
              setTensiometros([]);
              setExtratores([]);
              setIsModalOpen(true);
            } catch (err) {
              console.log(err);
            }
          }}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        <Button
          large
          intent="success"
          icon="search"
          type="button"
          text="Buscar"
          onClick={getLeituras}
        />
        <DateTime
            padding={0}
            label={`Data Inicial: ${moment(dataInicial).format('DD/MM/YYYY')}`}
            icon="calendar"
            onClickButton={() => setIsDateTimeInicialOpen(true)}
            isOpen={isDateTimeInicialOpen}
            headerFormat="DD/MM/YYYY"
            showCaption
            dateConfig={DiaConfig}
            value={dataInicial}
            onSelect={dataSelecionada => {
              setDataInicial(dataSelecionada);
              setIsDateTimeInicialOpen(false);
            }}
            onCancel={() => setIsDateTimeInicialOpen(false)}
          />
          <DateTime
            padding={0}
            label={`Data Final: ${moment(dataFinal).format('DD/MM/YYYY')}`}
            icon="calendar"
            onClickButton={() => setIsDateTimeFinalOpen(true)}
            isOpen={isDateTimeFinalOpen}
            headerFormat="DD/MM/YYYY"
            showCaption
            dateConfig={DiaConfig}
            min={dataInicial}
            value={dataFinal}
            onSelect={dataSelecionada => {
              setDataFinal(dataSelecionada);
              setIsDateTimeFinalOpen(false);
            }}
            onCancel={() => setIsDateTimeFinalOpen(false)}
          />
        </Opcoes>
    );
  }

  const onRowDoubleClicked = useCallback((item) => {
    try {
      const {
        id,
        dataLeitura,
        observacoes,
        pluviometro,
        bateria,
        leiturasTensiometros,
        leiturasExtratores
      } = item.data;

      setId(id);
      setDataLeitura(moment(dataLeitura).toDate());
      setObservacoes(observacoes);
      setPluviometro(pluviometro);
      setProjeto({ ...bateria.setor.projeto, setores: [] });
      setBateria(bateria);
      setTensiometros(
        bateria.tensiometros.map(t => {
          const leitura = leiturasTensiometros.find(
            l => l.tensiometro_id === t.id
          );
          return {
            id: t.id,
            leituraId: leitura.id,
            valor: leitura.valor,
            profundidade: t.profundidade
          };
        })
      );
      setExtratores(
        bateria.extratores.map(ex => {
          const leitura = leiturasExtratores.find(l => l.extrator_id === ex.id);
          return {
            id: ex.id,
            leituraId: leitura ? leitura.id : 0,
            valor: leitura ? leitura.valor : undefined,
            valorPh: leitura ? leitura.valorPh : undefined,
            profundidade: ex.profundidade,
            informar: !!leitura,
            informarPh: leitura ? !!leitura.valorPh : false
          };
        })
      );
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }, [])

  function cardContent(leitura) {
    return (
      <CardContent>
        <CardBody>
          <h4>{moment(leitura.dataLeitura).format("DD/MM/YYYY HH:mm")}</h4>
          <br />
          <span>Bateria: {leitura.bateria.descricao}</span>
          <span>
            Observações: {leitura.observacoes ? leitura.observacoes : "-"}
          </span>
          <span>
            Pluviômetro (mm):{" "}
            {leitura.pluviometro
              ? Number(leitura.pluviometro).toLocaleString()
              : "-"}
          </span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: leitura })}
          />
        </div>
      </CardContent>
    );
  }

  const handleChangeTensiometros = useCallback((id, value) => {
    const tensiometrosAux = [...tensiometros];
    const index = tensiometrosAux.findIndex(t => t.id === id);
    if (index > -1) {
      tensiometrosAux[index].valor = value;
      setTensiometros(tensiometrosAux);
    }
  }, [tensiometros])

  const handleChangeExtratores = useCallback((id, field, value) => {
    const extratoresAux = [...extratores];
    const index = extratoresAux.findIndex(t => t.id === id);
    if (index > -1) {
      extratoresAux[index][field] = value;
      if (field === "informar" && !value) {
        extratoresAux[index].valor = undefined;
        extratoresAux[index].valorPh = undefined;
        extratoresAux[index].informarPh = false;
      }
      if (field === "informarPh" && !value) extratoresAux[index].valorPh = undefined;
      setExtratores(extratoresAux);
    }
  }, [extratores])

  function modalContent() {
    return (
      <Form>
        <Select
          label="Projeto"
          items={listaProjetos}
          textValue={item => item.descricao}
          labelValue={item => item.fazenda.nome}
          itemKey="id"
          selected={projeto}
          onSelect={selected => {
            setProjeto(selected);
            setBateria({});
            setTensiometros([]);
            setExtratores([]);
          }}
          disabled={!cliente.id || id}
        />
        <Select
          label="Bateria"
          items={
            usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR
              ? listaBaterias.filter(b =>
                  projeto.setores.find(s => s.id === b.setor_id)
                )
              : listaBaterias.filter(b => b.projeto.id === projeto.id)
          }
          textValue={item => item.descricao}
          itemKey="id"
          selected={bateria}
          onSelect={selected => {
            setBateria(selected);
            setTensiometros(
              selected.tensiometros.map(t => ({
                ...t,
                valor: undefined
              }))
            );
            setExtratores(
              selected.extratores.map(ex => ({
                ...ex,
                valor: undefined,
                valorPh: undefined,
                informar: false,
                informarPh: false
              }))
            );
          }}
          disabled={!projeto.id || id}
        />

        <DateTime
          label={`Data e hora da leitura: ${moment(dataLeitura).format('DD/MM/YYYY HH:mm')}`}
          icon="calendar"
          onClickButton={() => setIsDateTimeOpen(true)}
          isOpen={isDateTimeOpen}
          headerFormat="DD/MM/YYYY hh:mm"
          showCaption
          dateConfig={DiaHoraConfig}
          max={moment().toDate()}
          value={dataLeitura}
          onSelect={dataSelecionada => {
            setDataLeitura(dataSelecionada);
            setIsDateTimeOpen(false);
          }}
          onCancel={() => setIsDateTimeOpen(false)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        {bateria.pluviometro ? (
          <NumberInput
            label="Pluviômetro (mm)"
            autoComplete="pluviometro"
            placeholder="pluviômetro"
            value={pluviometro}
            onChange={value => setPluviometro(value)}
            disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
          />
        ) : null}
        {tensiometros.length ? (<TituloSecao><h4>Tensiômetros</h4></TituloSecao>) : null}

        {tensiometros.length
          ? tensiometros.map(t => (
            <NumberInput
              key={t.id}
              label={`${t.profundidade}cm (mba)`}
              autoComplete="leitura"
              placeholder="leitura (mba)"
              value={t.valor}
              onChange={value => handleChangeTensiometros(t.id, value)}
              disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
            />
          ))
          : null}
        {extratores.length ? (<TituloSecao><h4>Extratores</h4></TituloSecao>) : null}
        {extratores.length
          ? extratores.map(ex => (
            <Fragment key={ex.id}>
              <NumberInput
                label={`${ex.profundidade}cm ((µS/cm)`}
                labelActions={
                  <Informar
                    large
                    label="Informar"
                    checked={ex.informar}
                    onChange={evt =>
                      handleChangeExtratores(
                        ex.id,
                        "informar",
                        evt.currentTarget.checked
                      )
                    }
                    disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                  />
                }
                autoComplete="leitura"
                placeholder="leitura ((µS/cm)"
                decimal
                value={ex.valor}
                onChange={value =>
                  handleChangeExtratores(ex.id, "valor", value)
                }
                disabled={!ex.informar || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
              />
              <NumberInput
                label={`${ex.profundidade}cm`}
                labelActions={
                  <Informar
                    large
                    label="Informar PH"
                    checked={ex.informarPh}
                    onChange={evt =>
                      handleChangeExtratores(
                        ex.id,
                        "informarPh",
                        evt.currentTarget.checked
                      )
                    }
                    disabled={!ex.informar || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                  />
                }
                autoComplete="leituraPh"
                placeholder="leitura PH"
                decimal
                value={ex.valorPh}
                onChange={value =>
                  handleChangeExtratores(ex.id, "valorPh", value)
                }
                disabled={!ex.informarPh || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
              />
            </Fragment>
          ))
          : null}
        {bateria.id ? (
          <Input
            textArea
            height="100px"
            label="Observações"
            autoComplete="observacoes"
            placeholder="observações diversas"
            value={observacoes}
            onChange={evt => setObservacoes(evt.currentTarget.value)}
            disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
          />
        ) : null}
      </Form>
    );
  }

  const canSave = useCallback(() => {
    let tensiometrosOK = true;
    let extratoresOK = true;
    tensiometros.forEach(t => {
      if (t.valor === null || t.valor === undefined) tensiometrosOK = false;
    });
    extratores.forEach(ex => {
      if (
        ((ex.valor === null || ex.valor === undefined) && ex.informar) ||
        ((ex.valorPh === null || ex.valorPh === undefined) && ex.informarPh))
        extratoresOK = false;
    });

    return bateria.id && tensiometrosOK && extratoresOK && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA;
  }, [bateria.id, extratores, tensiometros, usuarioLogado])

  function modalActions() {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        disabled={!canSave()}
        onClick={() => setIsConfirmationOpen(true)}
      />
    );
  }

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        observacoes,
        dataLeitura: moment(dataLeitura).toISOString(),
        bateria,
        pluviometro,
        tensiometros,
        extratores: extratores.filter(ex => ex.informar)
      };
      if (!id) {
        await Api.postRequest("leituras", dados);
      } else {
        await Api.putRequest("leituras", { ...dados, id });
      }
      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Leitura salva com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          setErrorCode(err.response.status);
          presentDialog(
            "Erro",
            `Ocorreu um erro ao salvar os dados. Erro: ${err.message}`
          );
        }
      }
    }
  }, [bateria, dataLeitura, extratores, id, observacoes, pluviometro, presentDialog, tensiometros])

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaLeituras}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes da leitura"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />
      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
