import { createGlobalStyle } from "styled-components";
import Colors from "./colors";

const GlobalStyles = createGlobalStyle`
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
        font-family: 'Montserrat', sans-serif;
    }

    html, body, #root {
      min-height: 100% !important;
      height: 100%;
    }

    body {
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;

      .ag-theme-blue {
          font-family: 'Montserrat', sans-serif;
          text-align: center;
        }
        .ag-theme-blue .ag-row-hover {
          background-color: ${Colors.TABLE_HOVER};
          .ag-cell {
            background-color: ${Colors.TABLE_HOVER} !important;
          }
        }
        .ag-theme-blue .ag-header {
          background-color: ${Colors.AZUL_HYDRA};
          .ag-header-cell-label {
            display: flex;
            justify-content: center;
          }
        }
        .ag-theme-blue .ag-row-odd {
          background-color: ${Colors.TABLE_ODD_ROW};
        }
        .ag-theme-blue .ag-row-selected {
          background-color: ${Colors.TABLE_ROW_SELECTED};
          .ag-cell {
            background-color: ${Colors.TABLE_ROW_SELECTED} !important;
          }
        }
    }

    button {
        cursor: pointer;
    }
`;

export default GlobalStyles;
