import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Toaster } from "@blueprintjs/core";

import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";

import moment from "moment";
import "moment/locale/pt-br";

import GlobalStyles from "./styles/global";
import { Content } from "./styles/components";

import Route from "./routes";

moment().locale("pt-br");

const toast = Toaster.create();
window.swHasUpdateAvailable = () => {
  toast.show({
    intent: "warning",
    message:
      "Existe uma atualização disponível. Por favor, reinicie o aplicativo",
    icon: "info-sign",
    timeout: 10000
  });
};

export default function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Content>
        <Route />
      </Content>
    </BrowserRouter>
  );
}
