import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";

export const DialogContainer = styled(Dialog)`
  width: 90%;
  margin: 0;
  overflow: hidden;

  @media (min-width: 700px) {
    width: 400px;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  height: 90%;
  overflow: auto;
  margin: 10px;
`;

export const DialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 5px 0 0 0;

  button {
    margin: 0 20px 0 0;
  }
`;
