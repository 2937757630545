/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Button, Intent, Spinner } from "@blueprintjs/core";
import { useStore } from "effector-react";

import Dialog from "../../components/Dialog";
import MyInput from "../../components/Input";

import Api from "../../services/api";
import LoginStore from "../../store/login";
import { PUSH_PUBLIC_KEY, urlB64ToUint8Array } from "../../services/consts";

import { Container, Card, Input, Actions } from "./styles";
import logo from "../../assets/images/logo-connect.png";

export default function Login(props) {
  const [loadingRefreshLogin, setLoadingRefreshLogin] = useState(false);
  const [loadingResetPasssword, setLoadingResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isResetPasswordEmailOpen, setIsResetPasswordEmailOpen] = useState(
    false
  );
  const [isTokenDialogOpen, setIsTokenDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailToSendReset, setEmailToSendReset] = useState("");
  const [tokenToReset, setTokenToReset] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const usuarioLogado = useStore(LoginStore.usuarioLogado);

  function getSubscriptions(swReg) {
    swReg.pushManager
      .getSubscription()
      .then(sub => {
        if (!sub) {
          return swReg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(PUSH_PUBLIC_KEY)
          });
        } else return null;
      })
      .then(newSub => {
        if (newSub) Api.postRequest("notificacoes/subscription", { newSub });
      });
  }

  function pedirPermissao() {
    try {
      if ("Notification" in window && "serviceWorker" in navigator) {
        Notification.requestPermission()
          .then(res => {
            if (res === "granted") {
              navigator.serviceWorker.ready
                .then(swReg => getSubscriptions(swReg))
                .catch(err => {
                  console.log(err);
                });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      
    } catch (error) {
      if (error instanceof TypeError) {
        Notification.requestPermission((res) => {
          if (res === "granted") {
            navigator.serviceWorker.ready
              .then(swReg => getSubscriptions(swReg))
              .catch(err => {
                console.log(err);
              });
          }
        });
      }
    }
  }

  useEffect(() => {
    async function autoLogin() {
      try {
        setLoadingRefreshLogin(true);
        const response = await Api.refreshLogin(
          usuarioLogado.username,
          usuarioLogado.refreshToken
        );
        pedirPermissao();
        LoginStore.salvarUsuarioLogado(response.data);
        props.history.push("/dashboard");
      } catch (err) {
        console.log(err);
        setLoadingRefreshLogin(false);
      }
    }
    if (usuarioLogado.id) autoLogin();
  }, []);

  function presentDialog(title, message) {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }

  async function handleSubmit(evt) {
    try {
      evt.preventDefault();
      setLoading(true);
      const response = await Api.login(username, password);
      pedirPermissao();
      LoginStore.salvarUsuarioLogado(response.data);
      props.history.push("/dashboard");
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      if (err.response) presentDialog("Erro", err.response.data.error.message);
      else
        presentDialog(
          "Erro",
          `Não foi possível realizar o login. Erro: ${err.message}`
        );
    }
  }

  async function sendEmailToReset() {
    try {
      setLoadingResetPassword(true);
      await Api.postRequest("recuperarSenha", { email: emailToSendReset });
      setLoadingResetPassword(false);
      setIsResetPasswordEmailOpen(false);
      presentDialog(
        "Sucesso",
        "E-mail enviado com sucesso. Verifique seu e-mail para utilizar o código"
      );
    } catch (err) {
      setLoadingResetPassword(false);
      setIsResetPasswordEmailOpen(false);
      presentDialog("Erro", "Não foi possível enviar o email");
    }
  }

  async function resetPassword() {
    try {
      setLoadingResetPassword(true);
      await Api.putRequest("recuperarSenha", {
        password: newPassword,
        token: tokenToReset
      });
      setLoadingResetPassword(false);
      setIsTokenDialogOpen(false);
      presentDialog("Sucesso", "Senha alterada");
    } catch (err) {
      setLoadingResetPassword(false);
      setIsTokenDialogOpen(false);
      presentDialog("Erro", "Não foi possível alterar a senha");
    }
  }

  return (
    <Container>
      {loadingRefreshLogin ? (
        <Spinner size={80} intent="success" />
      ) : (
        <Card elevation={4}>
          <img src={logo} alt="logo" />

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Input
              large
              leftIcon="user"
              type="email"
              placeholder="seu usuário"
              value={username}
              onChange={evt => setUsername(evt.target.value)}
              disabled={loading}
            />
            <Input
              large
              leftIcon="lock"
              type="password"
              placeholder="sua senha"
              value={password}
              onChange={evt => setPassword(evt.target.value)}
              disabled={loading}
            />
            <Actions>
              <Button
                large
                intent={Intent.PRIMARY}
                loading={loading}
                type="submit"
                icon="log-in"
                disabled={!username || !password}
              >
                Entrar
              </Button>
              {/* <Button
                large
                intent={Intent.WARNING}
                icon="key"
                disabled={loading}
                onClick={() => {
                  setEmailToSendReset("");
                  setTokenToReset("");
                  setNewPassword("");
                  setIsResetPasswordEmailOpen(true);
                }}
              >
                Recuperar senha
              </Button> */}
            </Actions>
          </form>
        </Card>
      )}
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button text="OK" onClick={() => setIsSuccessDialogOpen(false)} />
        }
      />
      <Dialog
        isOpen={isResetPasswordEmailOpen}
        onClose={() => setIsResetPasswordEmailOpen(false)}
        title="Recuperar senha"
        text={
          <MyInput
            type="email"
            label="E-mail"
            placeholder="E-mail para recuperar a senha"
            value={emailToSendReset}
            onChange={evt => setEmailToSendReset(evt.currentTarget.value)}
          />
        }
        actions={
          <>
            <Button
              text="Enviar e-mail"
              intent="primary"
              onClick={sendEmailToReset}
              loading={loadingResetPasssword}
              disabled={!emailToSendReset}
            />
            <Button
              text="Já tenho o código"
              intent="success"
              onClick={() => {
                setIsResetPasswordEmailOpen(false);
                setIsTokenDialogOpen(true);
              }}
            />
            <Button
              text="Cancelar"
              intent="danger"
              onClick={() => setIsResetPasswordEmailOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isTokenDialogOpen}
        onClose={() => setIsTokenDialogOpen(false)}
        title="Recuperar senha"
        text={
          <>
            <MyInput
              label="Código"
              placeholder="Código para recuperar a senha"
              value={tokenToReset}
              onChange={evt => setTokenToReset(evt.currentTarget.value)}
            />
            <MyInput
              label="Nova senha"
              placeholder="nova senha"
              value={newPassword}
              onChange={evt => setNewPassword(evt.currentTarget.value)}
            />
          </>
        }
        actions={
          <>
            <Button
              text="Atualizar a senha"
              intent="primary"
              onClick={resetPassword}
              loading={loadingResetPasssword}
              disabled={!tokenToReset || !newPassword}
            />
            <Button
              text="Cancelar"
              intent="danger"
              onClick={() => setIsTokenDialogOpen(false)}
            />
          </>
        }
      />
    </Container>
  );
}
