import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button } from "@blueprintjs/core";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Dialog from "../../components/Dialog";

import { Container, Form, CardContent, CardBody } from "./styles";

import ClientesStore from "../../store/clientes";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import { PerfisUsuarios } from "../../services/consts";

export default function Fazendas(props) {
  const colunas = [
    { headerName: "Nome", field: "nome" },
    { headerName: "Estado", field: "cidade.uf.nome" },
    { headerName: "Cidade", field: "cidade.nome" }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [id, setId] = useState(0);
  const [nome, setNome] = useState("");
  const [uf, setUf] = useState({});
  const [cidade, setCidade] = useState({});
  const [listaUfs, setListaUfs] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);
  const [listaFazendas, setListaFazendas] = useState([]);
  const cliente = useStore(ClientesStore.clienteSelecionado);
  const usuarioLogado = useStore(LoginStore.usuarioLogado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])
  
  const getFazendas = useCallback(async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`fazendas/${cliente.id}`);
      setListaFazendas(req.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [cliente.id, presentDialog])
  
  useEffect(() => {
    if (usuarioLogado.id) {
      if (usuarioLogado.perfil === PerfisUsuarios.ADMIN || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA) {
        if (cliente.id) getFazendas();
      } else props.history.push("/dashboard");
    } else props.history.push("/");
  }, [cliente.id, getFazendas, props.history, usuarioLogado]);

  const getUfsCidades = useCallback(async () => {
    try {
      setIsLoading(true);
      const reqUfs = await Api.getRequest("ufs");
      const reqCidades = await Api.getRequest("cidades");

      setListaUfs(reqUfs.data);
      setListaCidades(reqCidades.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
        }
      }
      throw err;
    }
  }, [presentDialog])

  function tableActions() {
    return (
      <Button
        large
        intent="success"
        icon="plus"
        type="button"
        text="Nova fazenda"
        onClick={async () => {
          try {
            if (!listaUfs.length || !listaCidades.length) await getUfsCidades();
            setId(0);
            setNome("");
            setUf({});
            setCidade({});
            setIsModalOpen(true);
          } catch (err) {
            console.log(err);
          }
        }}
        disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
      />
    );
  }

  const onRowDoubleClicked = useCallback(async (item) => {
    try {
      const { id, nome, cidade } = item.data;
      if (!listaUfs.length || !listaCidades.length) await getUfsCidades();
      setId(id);
      setNome(nome);
      setUf(cidade.uf);
      setCidade(cidade);
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }, [getUfsCidades, listaCidades.length, listaUfs.length])

  function cardContent(fazenda) {
    return (
      <CardContent>
        <CardBody>
          <h4>{fazenda.nome}</h4>
          <br />
          <span>Estado: {fazenda.cidade.uf.nome}</span>
          <span>Cidade: {fazenda.cidade.nome}</span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: fazenda })}
          />
        </div>
      </CardContent>
    );
  }

  function modalContent() {
    return (
      <Form>
        <Select
          label="UF"
          items={listaUfs}
          textValue={item => item.nome}
          labelValue={item => item.sigla}
          itemKey="id"
          selected={uf}
          onSelect={selected => {
            setCidade({});
            setUf(selected);
          }}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        <Select
          label="Cidade"
          items={listaCidades.filter(c => c.uf_id === uf.id)}
          textValue={item => item.nome}
          itemKey="id"
          selected={cidade}
          onSelect={selected => setCidade(selected)}
          disabled={!uf.id || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        <Input
          label="Nome"
          autoComplete="nome"
          placeholder="nome da fazenda"
          value={nome}
          onChange={evt => setNome(evt.currentTarget.value)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
      </Form>
    );
  }

  const canSave = useCallback(() => {
    return nome && cidade.id && cliente.id && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA;
  }, [cidade.id, cliente.id, nome, usuarioLogado])

  function modalActions() {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        disabled={!canSave()}
        onClick={() => setIsConfirmationOpen(true)}
      />
    );
  }

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        nome,
        cidade,
        cliente
      };
      if (!id) {
        await Api.postRequest("fazendas", dados);
      } else {
        await Api.putRequest("fazendas", { ...dados, id });
      }
      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Fazenda salva com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          setErrorCode(err.response.status);
          presentDialog(
            "Erro",
            `Ocorreu um erro ao salvar os dados. Erro: ${err.message}`
          );
        }
      }
    }
  }, [cidade, cliente, id, nome, presentDialog])

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaFazendas}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes da fazenda"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />
      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
