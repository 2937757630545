import React from "react";

import { ModalContainer, ModalContent, SpanDiv } from "./styles";

export default function ChartModal(props) {
  return (
    <ModalContainer
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      usePortal={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
    >
      <ModalContent>
        <SpanDiv>{props.children}</SpanDiv>
      </ModalContent>
    </ModalContainer>
  )
}
