import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";
import { Button, Icon, Switch } from "@blueprintjs/core";
import moment from "moment";
import * as mtz from "moment-timezone";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Select from "../../components/Select";
import NumberInput from "../../components/NumberInput";
import Dialog from "../../components/Dialog";
import DateTime from '../../components/DateTime'
import Input from "../../components/Input";

import {
  Container,
  Form,
  CardContent,
  CardBody,
  Setores,
  Setor,
  SetorContent,
  FormSetor,
  IrrigarFert,
  Informar,
  Opcoes
} from "./styles";

import ClienteStore from "../../store/clientes";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import { DiaConfig, DiaHoraConfig, HoraConfig, PerfisUsuarios } from "../../services/consts";

window.moment = moment;
mtz();

export default function Irrigacoes(props) {
  const colunas = [
    {
      headerName: "Data de início",
      field: "dataInicio",
      valueFormatter: params => moment(params.value).format("DD/MM/YYYY HH:mm")
    },
    { headerName: "Projeto", field: "projeto.descricao" },
    {
      headerName: "Setores irrigados",
      field: "setores",
      valueFormatter: params =>
        params.value
          ? [...params.value].map(s => s.setor.descricao).join(", ")
          : "-"
    },
    {
      headerName: "Hidrômetro (m³)",
      field: "valorHidrometro",
      valueFormatter: params =>
        !!params.value ? Number(params.value).toLocaleString() : "-"
    }
  ];

  const agora = moment().toDate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDateTimeOpen, setIsDateTimeOpen] = useState(false);
  const [isTimeDuracaoOpen, setIsTimeDuracaoOpen] = useState(false);
  const [isTimeInicioSetorOpen, setIsTimeInicioSetorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [id, setId] = useState(0);
  const [informarHidrametro, setInformarHidrometro] = useState(false);
  const [dataInicio, setDataInicio] = useState(agora);
  const [valorHidrometro, setValorHidrometro] = useState(undefined);
  const [observacoes, setObservacoes] = useState("");
  const [projeto, setProjeto] = useState({ cliente: {} });
  const [setores, setSetores] = useState([]);
  const [listaProjetos, setListaProjetos] = useState([]);
  const [listaIrrigacoes, setListaIrrigacoes] = useState([]);
  const [isDateTimeInicialOpen, setIsDateTimeInicialOpen] = useState(false);
  const [isDateTimeFinalOpen, setIsDateTimeFinalOpen] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment()
      .subtract(1, "weeks")
      .toDate()
  );
  const [dataFinal, setDataFinal] = useState(moment().toDate());
  const usuarioLogado = useStore(LoginStore.usuarioLogado);
  const cliente = useStore(ClienteStore.clienteSelecionado);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])

  const getIrrigacoes = async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`irrigacoes/${cliente.id}?inicio=${moment(dataInicial).format("YYYY-MM-DD")}&final=${moment(dataFinal).format("YYYY-MM-DD")}`);
      setListaIrrigacoes(req.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }
  
  useEffect(() => {
    if (usuarioLogado.id) {
      if (cliente.id) getIrrigacoes();
    } else props.history.push("/");
  }, [cliente.id, props.history, usuarioLogado]);

  function tableActions() {
    return (
      <Opcoes>
        <Button
          large
          intent="success"
          icon="plus"
          type="button"
          text="Nova Irrigação"
          onClick={async () => {
            try {
              await getProjetos();
              setId(0);
              setValorHidrometro(undefined);
              setObservacoes("");
              setDataInicio(agora);
              setProjeto({});
              setSetores([]);
              setIsModalOpen(true);
            } catch (err) {
              console.log(err);
            }
          }}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        <Button
          large
          intent="success"
          icon="search"
          type="button"
          text="Buscar"
          onClick={getIrrigacoes}
        />
        <DateTime
            padding={0}
            label={`Data Inicial: ${moment(dataInicial).format('DD/MM/YYYY')}`}
            icon="calendar"
            onClickButton={() => setIsDateTimeInicialOpen(true)}
            isOpen={isDateTimeInicialOpen}
            headerFormat="DD/MM/YYYY"
            showCaption
            dateConfig={DiaConfig}
            value={dataInicial}
            onSelect={dataSelecionada => {
              setDataInicial(dataSelecionada);
              setIsDateTimeInicialOpen(false);
            }}
            onCancel={() => setIsDateTimeInicialOpen(false)}
          />
          <DateTime
            padding={0}
            label={`Data Final: ${moment(dataFinal).format('DD/MM/YYYY')}`}
            icon="calendar"
            onClickButton={() => setIsDateTimeFinalOpen(true)}
            isOpen={isDateTimeFinalOpen}
            headerFormat="DD/MM/YYYY"
            showCaption
            dateConfig={DiaConfig}
            min={dataInicial}
            value={dataFinal}
            onSelect={dataSelecionada => {
              setDataFinal(dataSelecionada);
              setIsDateTimeFinalOpen(false);
            }}
            onCancel={() => setIsDateTimeFinalOpen(false)}
          />
        </Opcoes>
    );
  }

  const getProjetos = useCallback(async () => {
    try {
      setIsLoading(true);
      const reqProjetos = await Api.getRequest(`projetos/${cliente.id}`);
      setListaProjetos(reqProjetos.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
        }
      }
      throw err;
    }
  }, [cliente.id, presentDialog])

  const onRowDoubleClicked = useCallback(async (item) => {
    try {
      const { id, dataInicio, projeto, setores, valorHidrometro, observacoes } = item.data;
      await getProjetos();
      setId(id);
      setInformarHidrometro(!!valorHidrometro);
      setObservacoes(observacoes);
      setValorHidrometro(!!valorHidrometro ? valorHidrometro : undefined);
      setDataInicio(moment(dataInicio).toDate());
      setProjeto(projeto);
      setSetores(
        projeto.setores.map(s => {
          const irr = setores.find(set => set.setor_id === s.id);
          return {
            id: s.id,
            descricao: s.descricao,
            irrigacaoSetorId: irr ? irr.id : 0,
            irrigar: !!irr,
            fertirrigacao: irr ? !!irr.fertirrigacao : false,
            duracao: irr ? moment(irr.duracao).toDate() : moment().toDate(),
            horarioInicio: irr ? moment(irr.horarioInicio).toDate() : moment().toDate(),
            isOpen: false
          };
        })
      );
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }, [getProjetos])

  function cardContent(irrigacao) {
    return (
      <CardContent>
        <CardBody>
          <h4>{moment(irrigacao.dataInicio).format("DD/MM/YYYY HH:mm")}</h4>
          <br />
          <span>Projeto: {irrigacao.projeto.descricao}</span>
          <span>
            Setores irrigados:{" "}
            {irrigacao.setores.length
              ? irrigacao.setores.map(s => s.setor.descricao).join(", ")
              : "-"}
          </span>
          <span>
            Hidrômetro:{" "}
            {irrigacao.valorHidrometro
              ? Number(irrigacao.valorHidrometro).toLocaleString()
              : "-"}
          </span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: irrigacao })}
          />
        </div>
      </CardContent>
    );
  }

  const handleOpenSetor = useCallback((setor) => {
    setSetores(
      setores.map(s =>
        s.id === setor.id
          ? { ...s, isOpen: !setor.isOpen }
          : { ...s, isOpen: false }
      )
    );
  }, [setores])

  const handleChangeSetor = useCallback((setor, field, newValue) => {
    setSetores(
      setores.map(s => {
        if (s.id === setor.id) {
          if (field === "irrigar" && !newValue)
            return { ...s, irrigar: false, fertirrigacao: false };
          else return { ...s, [field]: newValue };
        } else return s;
      })
    );
  }, [setores])

  const handleSelectProjeto = useCallback((projeto) => {
    const horaMinutoInicial = moment()
      .hour(0)
      .minute(0)
      .second(0)
      .toDate();
    setProjeto(projeto);
    setSetores(
      projeto.setores.map(s => ({
        id: s.id,
        descricao: s.descricao,
        isOpen: false,
        irrigar: false,
        fertirrigacao: false,
        duracao: horaMinutoInicial,
        horarioInicio: moment().toDate()
      }))
    );
  }, [])

  function setoresRender() {
    return (
      <Setores>
        {setores.map(s => (
          <Fragment key={s.id}>
            <Setor interactive elevation={0} onClick={() => handleOpenSetor(s)} disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}>
              {s.descricao}
              <Icon icon={s.isOpen ? "chevron-down" : "chevron-left"} />
            </Setor>
            <SetorContent isOpen={s.isOpen}>
              <FormSetor>
                <IrrigarFert>
                  <Switch
                    large
                    label="Irrigar"
                    checked={s.irrigar}
                    onChange={evt =>
                      handleChangeSetor(s, "irrigar", evt.currentTarget.checked)
                    }
                    disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                  />
                  <Switch
                    large
                    label="Fertirrigação"
                    checked={s.fertirrigacao}
                    onChange={evt =>
                      handleChangeSetor(
                        s,
                        "fertirrigacao",
                        evt.currentTarget.checked
                      )
                    }
                    disabled={!s.irrigar || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                  />
                </IrrigarFert>
                {s.irrigar ? (
                  <>
                    <DateTime
                      label={`Duração da irrigação: ${moment(s.duracao).format('HH:mm')}`}
                      icon="time"
                      onClickButton={() => setIsTimeDuracaoOpen(true)}
                      disabled={!s.irrigar}
                      isOpen={isTimeDuracaoOpen}
                      headerFormat="hh:mm"
                      showCaption
                      dateConfig={HoraConfig}
                      value={s.duracao}
                      onSelect={duracaoSelecionada => {
                        handleChangeSetor(s, "duracao", duracaoSelecionada);
                        setIsTimeDuracaoOpen(false);
                      }}
                      onCancel={() => setIsTimeDuracaoOpen(false)}
                      disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                    />
                    <DateTime
                      label={`Inicio do setor: ${moment(s.horarioInicio).format('HH:mm')}`}
                      icon="time"
                      onClickButton={() => setIsTimeInicioSetorOpen(true)}
                      disabled={!s.irrigar}
                      isOpen={isTimeInicioSetorOpen}
                      headerFormat="DD/MM/YYYY hh:mm"
                      showCaption
                      dateConfig={DiaHoraConfig}
                      min={dataInicio}
                      max={moment().add(1, 'day').toDate()}
                      value={s.horarioInicio}
                      onSelect={inicioSelecionado => {
                        handleChangeSetor(s, "horarioInicio", inicioSelecionado);
                        setIsTimeInicioSetorOpen(false);
                      }}
                      onCancel={() => setIsTimeInicioSetorOpen(false)}
                      disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
                    />
                  </>
                ) : null}
              </FormSetor>
            </SetorContent>
          </Fragment>
        ))}
      </Setores>
    );
  }

  function modalContent() {
    return (
      <Form>
        <Select
          label="Projeto"
          items={listaProjetos}
          textValue={item => item.descricao}
          itemKey="id"
          selected={projeto}
          onSelect={handleSelectProjeto}
          disabled={id || !cliente.id}
        />
        <DateTime
          label={`Início da irrigação: ${moment(dataInicio).format('DD/MM/YYYY HH:mm')}`}
          icon="calendar"
          onClickButton={() => setIsDateTimeOpen(true)}
          isOpen={isDateTimeOpen}
          headerFormat="DD/MM/YYYY hh:mm"
          showCaption
          dateConfig={DiaHoraConfig}
          max={moment().toDate()}
          value={dataInicio}
          onSelect={dataSelecionada => {
            setDataInicio(dataSelecionada);
            setIsDateTimeOpen(false);
          }}
          onCancel={() => setIsDateTimeOpen(false)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
        {setoresRender()}
        {setores.length ? (
          <NumberInput
            label="Leitura hidrômetro (m³)"
            labelActions={
              <Informar
                large
                label="Informar hidrômetro"
                checked={informarHidrametro}
                onChange={evt => {
                  setInformarHidrometro(evt.currentTarget.checked);
                  if (!evt.currentTarget.checked) setValorHidrometro(undefined);
                }}
                disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
              />
            }
            autoComplete="hidrometro"
            placeholder="leitura do hidrômetro (m³)"
            value={valorHidrometro}
            onChange={value => setValorHidrometro(value)}
            disabled={!informarHidrametro || usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
          />
        ) : null}
        <Input
          textArea
          height="100px"
          label="Observações"
          autoComplete="observacoes"
          placeholder="observações diversas"
          value={observacoes}
          onChange={evt => setObservacoes(evt.currentTarget.value)}
          disabled={usuarioLogado.perfil === PerfisUsuarios.SUPERVISOR_HYDRA}
        />
      </Form>
    );
  }

  const canSave = useCallback(() => {
    const setoresIrrigar = setores.filter(s => s.irrigar);
    let setoresOK = !!setoresIrrigar.length > 0;
    setoresIrrigar.forEach(s => {
      const d = moment(s.duracao);
      const duracaoZero = d.hour() === 0 && d.minute() === 0;
      if (s.duracao === null || s.duracao === undefined || duracaoZero)
        setoresOK = false;
    });

    return informarHidrametro
      ? projeto.id && dataInicio && valorHidrometro && setoresOK && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA
      : projeto.id && dataInicio && setoresOK && usuarioLogado.perfil !== PerfisUsuarios.SUPERVISOR_HYDRA;
  }, [dataInicio, informarHidrametro, projeto.id, setores, valorHidrometro])

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        dataInicio: moment(dataInicio).toISOString(),
        setores: setores
          .filter(s => s.irrigar)
          .map(s => ({
            ...s,
            duracao: moment(s.duracao).format("YYYY-MM-DDTHH:mm:ss"),
            horarioInicio: moment(s.horarioInicio).format("YYYY-MM-DDTHH:mm:ss")
          })),
        projeto,
        valorHidrometro,
        observacoes
      };
      if (!id) {
        await Api.postRequest("irrigacoes", dados);
      } else {
        await Api.putRequest("irrigacoes", { ...dados, id });
      }
      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Irrigação salva com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          setErrorCode(err.response.status);
          presentDialog(
            "Erro",
            `Ocorreu um erro ao salvar os dados. Erro: ${err.message}`
          );
        }
      }
    }
  }, [dataInicio, id, presentDialog, projeto, setores, valorHidrometro, observacoes])

  function modalActions() {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        onClick={() => setIsConfirmationOpen(true)}
        disabled={!canSave()}
      />
    );
  }

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaIrrigacoes}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes da irrigação"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />
      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
