import { createStore, createEvent, createEffect } from "effector";
import Api from "../services/api";

const loading = createStore(false);
const setLoading = createEvent();
const lista = createStore([]);
const clienteSelecionado = createStore({});
const error = createStore(false);
const errorCode = createStore(0);
const errorMessage = createStore("Não foi possível buscar os clientes");
const setLista = createEvent();
const setClienteSelecionado = createEvent();
const setError = createEvent();
const setErrorCode = createEvent();
const setErrorMessage = createEvent();
const get = createEffect().use(params => {
  setLoading(true);
  return Api.getRequest("clientes")
    .then(res => {
      setLoading(false);
      return res.data;
    })
    .catch(err => {
      setLoading(false);
      if (err.response) {
        setErrorCode(err.response.status);
        if (err.response.status === 401) {
          setErrorMessage(
            "Não foi possível buscar os clientes. Sessão expirada"
          );
        }
      } else setErrorCode(0);
      setError(true);
    });
});

loading.on(setLoading, (state, payload) => payload);

lista.on(setLista, (state, payload) => payload);
clienteSelecionado.on(setClienteSelecionado, (state, payload) => payload);

get.done.watch(({ result, params }) => {
  setLista(result);
  if (result && result.length) setClienteSelecionado(result[0]);
});

error.on(setError, (state, payload) => payload);
errorCode.on(setErrorCode, (state, payload) => payload);
errorMessage.on(setErrorMessage, (state, payload) => payload);

export default {
  loading,
  lista,
  get,
  clienteSelecionado,
  setClienteSelecionado,
  setLista,
  error,
  setError,
  errorCode,
  setErrorCode,
  errorMessage,
  setErrorMessage
};
