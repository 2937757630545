import axios from "axios";
import { getUsuarioLogado } from "./localDB";

const API_URL = "https://api.connect.hydrairrigacoes.com.br/";

const api = axios.create({ baseURL: API_URL });

const getRequest = (tabela) =>
  api.get(tabela, {
    headers: { Authorization: `bearer ${getUsuarioLogado().token}` },
  });
const postRequest = (tabela, data) =>
  api.post(tabela, data, {
    headers: { Authorization: `bearer ${getUsuarioLogado().token}` },
  });
const putRequest = (tabela, data) =>
  api.put(tabela, data, {
    headers: { Authorization: `bearer ${getUsuarioLogado().token}` },
  });

const login = (username, password) => {
  return api.post("login", { username, password });
};

const refreshLogin = (username, refreshToken) => {
  return api.post("refreshToken", {
    username,
    refreshToken,
  });
};

export default {
  getRequest,
  postRequest,
  putRequest,
  login,
  refreshLogin,
};
