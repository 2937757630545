import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0 0 0 5px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
`;

export const FormSensores = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const AdicionarSensor = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  margin: 10px 0 5px 0;

  h4 {
    margin: 0 0 0 10px;
    font-size: 16px;
    font-weight: bold;
    width: 120px;
  }
`;

export const Profundidades = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;

  button {
    margin: 20px 0 0 0;
  }
`;
