export const DiaConfig = {
  'date': {
      format: 'DD',
      caption: 'Dia',
      step: 1,
  },
  'month': {
    format: 'MM',
    caption: 'Mês',
    step: 1,
  },
  'year': {
    format: 'YYYY',
    caption: 'Ano',
    step: 1,
  }
};

export const DiaHoraConfig = {
  'date': {
      format: 'DD',
      caption: 'Dia',
      step: 1,
  },
  'month': {
    format: 'MM',
    caption: 'Mês',
    step: 1,
  },
  'year': {
    format: 'YYYY',
    caption: 'Ano',
    step: 1,
  },
  'hour': {
      format: 'hh',
      caption: 'Hora',
      step: 1,
  },
  'minute': {
      format: 'mm',
      caption: 'Min',
      step: 1,
  }
};

export const HoraConfig = {
  'hour': {
      format: 'hh',
      caption: 'Hora',
      step: 1,
  },
  'minute': {
      format: 'mm',
      caption: 'Min',
      step: 1,
  }
};

export const PerfisUsuarios = {
  ADMIN: 1,
  CLIENTE: 2,
  IRRIGADOR: 3,
  SUPERVISOR: 4,
  SUPERVISOR_HYDRA: 5,
  properties: [
    { text: "Administrador", value: 1 },
    { text: "Cliente", value: 2 },
    { text: "Irrigador", value: 3 },
    { text: "Supervisor", value: 4 },
    { text: "Supervisor Hydra", value: 5 }
  ]
};

export const PUSH_PUBLIC_KEY =
  "BDkOrZ00fvHo_OqP5lzNqho1bVWQzIN1Ha3KKOjP5ggRSaJ1DkIXLhvEoKk3OVsreDpYHwNxrna6K4xKOyIB54U";
export const PUSH_PRIVATE_KEY = "VUAswOXIJHbBvyveL4N9YTBk9USD72hi1iX241Nev1Y";
export const SecretKey = "6A576E5A7234753778214125442A472D";

export const urlB64ToUint8Array = base64String => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const ChartTranslation = {
  lang: {
    loading: "Carregando...",
    noData: "Sem dados",
    resetZoom: "Zoom inicial",
    resetZoomTitle: "Voltar ao zoom inicial",
    printChart: "Imprimir",
    downloadCSV: "Download em CSV",
    downloadJPEG: "Download em JPEG",
    downloadPDF: "Download em PDF",
    downloadPNG: "Download em PNG",
    downloadSVG: "Download em SVG",
    downloadXLS: "Download em XLS",
    viewFullscreen: "Ver em tela cheia",
    decimalPoint: ",",
    thousandsSep: ".",
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez"
    ],
    weekdays: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado"
    ]
  }
};

export const TensiometroChartOptions = {
  chart: {
    zoomType: "xy"
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ],
      }
    }
  },
  time: {
    timezone: "America/Sao_Paulo"
  },
  title: {
    text: ""
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: "%e/%b/%Y %H:%M",
      second: "%e/%b/%Y %H:%M",
      minute: "%e/%b/%Y %H:%M",
      hour: "%e/%b/%Y %H:%M",
      day: "%e/%b/%Y %H:%M",
      week: "%e/%b/%Y %H:%M",
      month: "%e/%b/%Y %H:%M",
      year: "%e/%b/%Y %H:%M"
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: "16px"
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: "14px"
    }
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%H:%M",
      second: "%H:%M",
      minute: "%H:%M",
      hour: "%H:%M",
      day: "%e/%b",
      week: "%e/%b",
      month: "%e/%b/%y",
      year: "%Y"
    },
    labels: {
      style: {
        fontSize: "12px"
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: "{value}",
        style: {
          fontSize: "12px"
        }
      },
      title: {
        text: "Leitura (mba)"
      },
      min: 0,
      max: 700,
      tickPositioner: () => [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700],
      plotBands: [
        {
          // Saturado
          from: 0,
          to: 50,
          color: "rgba(0, 0, 255, 0.2)",
          label: {
            text: "Solo saturado",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // Ideal
          from: 50,
          to: 150,
          color: "rgba(0, 255, 0, 0.2)",
          label: {
            text: "Ideal",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // Seco
          from: 150,
          to: 300,
          color: "rgba(255, 0, 0, 0.2)",
          label: {
            text: "Solo seco",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // Ponto de murcha
          from: 300,
          to: 800,
          color: "rgba(200, 200, 200, 0.2)",
          label: {
            text: "Ponto de murcha",
            style: {
              color: "red",
              fontSize: "16px",
              fontWeight: "bold",
              alignSelf: "left"
            }
          }
        }
      ]
    },
    {
      gridLineWidth: 0,
      title: {
        text: "Chuvas / Irrigação (mm)"
      },
      labels: {
        format: "{value}",
        style: {
          fontSize: "12px"
        }
      },
      min: 0,
      max: 50,
      opposite: true,
      reversed: true
    }
  ]
};

export const ExtratorChartOptions = {
  chart: {
    zoomType: "xy"
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200
  },
  time: {
    timezone: "America/Sao_Paulo"
  },
  title: {
    text: ""
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: "%e/%b/%Y %H:%M",
      second: "%e/%b/%Y %H:%M",
      minute: "%e/%b/%Y %H:%M",
      hour: "%e/%b/%Y %H:%M",
      day: "%e/%b/%Y %H:%M",
      week: "%e/%b/%Y %H:%M",
      month: "%e/%b/%Y %H:%M",
      year: "%e/%b/%Y %H:%M"
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: "16px"
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: "14px"
    }
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%H:%M",
      second: "%H:%M",
      minute: "%H:%M",
      hour: "%H:%M",
      day: "%e/%b",
      week: "%e/%b",
      month: "%e/%b/%y",
      year: "%Y"
    },
    labels: {
      style: {
        fontSize: "12px"
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: "{value}",
        style: {
          fontSize: "12px"
        }
      },
      title: {
        text: "Leitura (µS/cm)"
      },
      min: 0,
      max: 3000,
      tickPositioner: () => [0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000],
      plotBands: [
        {
          // EC Baixa
          from: 0,
          to: 1000,
          color: "rgba(0, 0, 255, 0.2)",
          label: {
            text: "EC Baixa",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // Ideal
          from: 1000,
          to: 1500,
          color: "rgba(0, 255, 0, 0.2)",
          label: {
            text: "EC Ideal",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // EC Alta
          from: 1500,
          to: 3000,
          color: "rgba(255, 0, 0, 0.2)",
          label: {
            text: "EC Alta",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        }
      ]
    },
    {
      gridLineWidth: 0,
      title: {
        text: "Chuvas / Irrigação (mm)"
      },
      labels: {
        format: "{value}",
        style: {
          fontSize: "12px"
        }
      },
      min: 0,
      max: 50,
      opposite: true,
      reversed: true
    }
  ]
};

export const PhChartOptions = {
  chart: {
    zoomType: "xy"
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200
  },
  time: {
    timezone: "America/Sao_Paulo"
  },
  title: {
    text: ""
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: "%e/%b/%Y %H:%M",
      second: "%e/%b/%Y %H:%M",
      minute: "%e/%b/%Y %H:%M",
      hour: "%e/%b/%Y %H:%M",
      day: "%e/%b/%Y %H:%M",
      week: "%e/%b/%Y %H:%M",
      month: "%e/%b/%Y %H:%M",
      year: "%e/%b/%Y %H:%M"
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: "16px"
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: "14px"
    }
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%H:%M",
      second: "%H:%M",
      minute: "%H:%M",
      hour: "%H:%M",
      day: "%e/%b",
      week: "%e/%b",
      month: "%e/%b/%y",
      year: "%Y"
    },
    labels: {
      style: {
        fontSize: "12px"
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: "{value}",
        style: {
          fontSize: "12px"
        }
      },
      title: {
        text: "Leitura"
      },
      min: 3,
      max: 10,
      tickPositioner: () => [3,4,5,6,7,8,9,10],
      plotBands: [
        {
          // Ácido
          from: 3,
          to: 6,
          color: "rgba(0, 0, 255, 0.2)",
          label: {
            text: "Ácido",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // Ideal
          from: 6,
          to: 7,
          color: "rgba(0, 255, 0, 0.2)",
          label: {
            text: "Ideal",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        },
        {
          // Básico
          from: 7,
          to: 10,
          color: "rgba(255, 0, 0, 0.2)",
          label: {
            text: "Básico",
            style: {
              color: "#000",
              fontSize: "16px",
              fontWeight: "bold"
            }
          }
        }
      ]
    },
    {
      gridLineWidth: 0,
      title: {
        text: "Chuvas / Irrigação (mm)"
      },
      labels: {
        format: "{value}",
        style: {
          fontSize: "12px"
        }
      },
      min: 0,
      max: 50,
      opposite: true,
      reversed: true
    }
  ]
};

export const AgGridPtBr = {
  // for filter panel
  page: "Página",
  more: "Mais",
  to: "Para",
  of: "De",
  next: "Próximo",
  last: "Último",
  first: "Primeiro",
  previous: "Anterior",
  loadingOoo: "Carregando...",

  // for set filter
  selectAll: "Selecionar todos",
  searchOoo: "Buscando...",
  blanks: "em branco",

  // for number filter and text filter
  filterOoo: "Filtrando...",
  applyFilter: "Aplicando filtro...",
  equals: "igual",
  notEquals: "diferente",

  // for number filter
  lessThan: "menor que",
  greaterThan: "maior que",
  lessThanOrEqual: "menor ou igual que",
  greaterThanOrEqual: "maior ou igual que",
  inRange: "intervalo",

  // for text filter
  contains: "contém",
  notContains: "não contém",
  startsWith: "começa com",
  endsWith: "termina com",

  // filter conditions
  andCondition: "e",
  orCondition: "ou",

  // the header of the default group column
  group: "grupo",

  // tool panel
  columns: "colunas",
  filters: "filtros",
  rowGroupColumns: "laPivot Cols",
  rowGroupColumnsEmptyMessage: "la drag cols to group",
  valueColumns: "laValue Cols",
  pivotMode: "laPivot-Mode",
  groups: "laGroups",
  values: "laValues",
  pivots: "laPivots",
  valueColumnsEmptyMessage: "la drag cols to aggregate",
  pivotColumnsEmptyMessage: "la drag here to pivot",
  toolPanelButton: "la tool panel",

  // other
  noRowsToShow: "sem registros",

  // enterprise menu
  pinColumn: "laPin Column",
  valueAggregation: "laValue Agg",
  autosizeThiscolumn: "laAutosize Diz",
  autosizeAllColumns: "laAutsoie em All",
  groupBy: "laGroup by",
  ungroupBy: "laUnGroup by",
  resetColumns: "laReset Those Cols",
  expandAll: "laOpen-em-up",
  collapseAll: "laClose-em-up",
  toolPanel: "laTool Panelo",
  export: "laExporto",
  csvExport: "la CSV Exportp",
  excelExport: "la Excel Exporto (.xlsx)",
  excelXmlExport: "la Excel Exporto (.xml)",

  // enterprise menu pinning
  pinLeft: "laPin <<",
  pinRight: "laPin >>",
  noPin: "laDontPin <>",

  // enterprise menu aggregation and status bar
  sum: "laSum",
  min: "laMin",
  max: "laMax",
  none: "laNone",
  count: "laCount",
  average: "laAverage",
  filteredRows: "laFiltered",
  selectedRows: "laSelected",
  totalRows: "laTotal Rows",
  totalAndFilteredRows: "laRows",

  // standard menu
  copy: "laCopy",
  copyWithHeaders: "laCopy Wit hHeaders",
  ctrlC: "ctrl n C",
  paste: "laPaste",
  ctrlV: "ctrl n V"
};
