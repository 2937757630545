import { createStore, createEvent } from "effector";
import {
  salvarUsuarioLogado,
  excluirDados,
  getUsuarioLogado
} from "../services/localDB";

const UsuarioLogado = createStore(getUsuarioLogado());
const saveUser = createEvent();
const logOut = createEvent();

UsuarioLogado.on(saveUser, (state, payload) => {
  salvarUsuarioLogado(payload);
  return payload;
});

UsuarioLogado.on(logOut, (state, payload) => {
  excluirDados();
  return {};
});

export default {
  usuarioLogado: UsuarioLogado,
  salvarUsuarioLogado: saveUser,
  logOut
};
