import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";

export const ModalContainer = styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden !important;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
`;

export const SpanDiv = styled.span`
  width: 100%;
  height: 100%;
`
