import styled from "styled-components";
import { Card, Collapse } from "@blueprintjs/core";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0 0 0 5px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const FormSetor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const Setores = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

export const AdicionarSetores = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  margin: 10px 0 5px 0;

  h4 {
    margin: 0 0 0 10px;
    font-size: 16px;
    font-weight: bold;
    width: 120px;
  }
`;

export const Setor = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;

  button {
    margin: 0 5px 0 0;
  }
`;

export const SetorContent = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .bp3-collapse-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
`;
