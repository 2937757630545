import React, { useState, useEffect } from "react";
import { Spinner } from "@blueprintjs/core";
import { AgGridReact } from "ag-grid-react";

import Modal from "../Modal";
import {
  Container,
  TableActions,
  SearchBar,
  Tabela,
  Cards,
  MobileCard
} from "./styles";

import { AgGridPtBr } from "../../services/consts";

export default function ElementsPresentation(props) {
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState([...props.rowData]);

  function filterList(search = "") {
    setSearchText(search);
    if (!search) setFilteredList([...props.rowData]);
    else {
      const listAux = props.rowData.filter(item => {
        let hasMatch = false;
        Object.keys(item).forEach(key => {
          if (
            String(item[key])
              .toLowerCase()
              .includes(String(search).toLowerCase())
          )
            hasMatch = true;
        });
        return hasMatch;
      });
      setFilteredList(listAux);
    }
  }

  useEffect(filterList, [props.rowData]);

  return (
    <Container loading={props.loading}>
      {props.loading ? (
        <Spinner intent="primary" size={70} />
      ) : (
        <>
          <TableActions>
            {props.tableActions}
            <SearchBar
              large
              leftIcon="search"
              intent="primary"
              placeholder="pesquisar"
              value={searchText}
              onChange={evt => filterList(evt.currentTarget.value)}
            />
          </TableActions>

          <Tabela className="ag-theme-blue">
            <AgGridReact
              onGridReady={params => params.api.sizeColumnsToFit()}
              rowSelection="single"
              onRowDoubleClicked={props.onRowDoubleClicked}
              columnDefs={props.columnDefs}
              rowData={filteredList}
              localeText={AgGridPtBr}
            />
          </Tabela>
          <Cards>
            {filteredList.length ? (
              filteredList.map(item => (
                <MobileCard key={item.id} elevation={2}>
                  {props.cardContent(item)}
                </MobileCard>
              ))
            ) : (
              <span>Sem registros</span>
            )}
          </Cards>
          <Modal
            isOpen={props.isModalOpen}
            onClose={props.onCloseModal}
            title={props.modalTitle}
            actions={props.modalActions}
          >
            {props.modalContent}
          </Modal>
        </>
      )}
    </Container>
  );
}
