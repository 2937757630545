import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import { Button, Switch } from "@blueprintjs/core";

import Navbar from "../../components/Navbar";
import ElementsPresentation from "../../components/ElementsPresentation";
import Select from "../../components/Select";
import Input from "../../components/Input";
import PhoneInput from "../../components/PhoneInput";
import Dialog from "../../components/Dialog";

import { Container, Form, CardContent, CardBody } from "./styles";

import { PerfisUsuarios } from "../../services/consts";
import Api from "../../services/api";
import LoginStore from "../../store/login";
import ClienteStore from "../../store/clientes";

export default function Usuarios(props) {
  const colunas = [
    { headerName: "Nome", field: "nome" },
    {
      headerName: "Projetos",
      field: "projetos",
      valueFormatter: params =>
        params.value.length
          ? params.value.map(p => p.descricao).join(", ")
          : "-"
    },
    {
      headerName: "Perfil",
      field: "perfil",
      valueFormatter: params =>
        PerfisUsuarios.properties.find(p => p.value === params.value).text
    },
    {
      headerName: "Ativo",
      field: "ativo",
      valueFormatter: params => (params.value ? "Sim" : "Não")
    }
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [projetos, setProjetos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [id, setId] = useState(0);
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [password, setPassword] = useState("");
  const [perfil, setPerfil] = useState(PerfisUsuarios.IRRIGADOR);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaProjetos, setListaProjetos] = useState([]);
  const usuarioLogado = useStore(LoginStore.usuarioLogado);
  const cliente = useStore(ClienteStore.clienteSelecionado);
  const listaClientes = useStore(ClienteStore.lista);

  const presentDialog = useCallback((title, message) => {
    setSuccessTitle(title);
    setSuccessMessage(message);
    setIsSuccessDialogOpen(true);
  }, [])

  const getUsuarios = useCallback(async () => {
    try {
      setIsLoading(true);
      const req = await Api.getRequest(`usuarios/${cliente.id}`);
      setListaUsuarios(
        req.data.map(u => ({
          ...u,
          projetos: u.perfil === PerfisUsuarios.ADMIN || u.perfil === PerfisUsuarios.SUPERVISOR | u.perfil === PerfisUsuarios.SUPERVISOR_HYDRA ? [] : u.projetos
        }))
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setErrorCode(401);
        presentDialog("Erro", `Sessão expirada`);
      } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
      }
    }
  }, [cliente.id, presentDialog])
  
  useEffect(() => {
    if (usuarioLogado.id) {
      if (usuarioLogado.perfil === PerfisUsuarios.ADMIN) {
        if (cliente.id) getUsuarios();
      } else props.history.push("/dashboard");
    } else props.history.push("/");
  }, [cliente.id, props.history, usuarioLogado, presentDialog, getUsuarios]);

  const getProjetos = useCallback(async () => {
    try {
      setIsLoading(true);
      const reqProjetos = await Api.getRequest(`projetos/${cliente.id}`);
      setListaProjetos(reqProjetos.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          presentDialog(
            "Erro",
            `Ocorreu um erro ao buscar os dados. Erro: ${err.message}`
          );
        }
      }
      throw err;
    }
  }, [cliente.id, presentDialog])

  function tableActions() {
    return (
      <Button
        large
        intent="success"
        icon="plus"
        type="button"
        text="Novo usuário"
        onClick={async () => {
          try {
            await getProjetos();
            setId(0);
            setNome("");
            setUsername("");
            setEmail("");
            setTelefone("");
            setPassword("");
            setProjetos([]);
            setPerfil(PerfisUsuarios.IRRIGADOR);
            setIsModalOpen(true);
          } catch (err) {
            console.log(err);
          }
        }}
      />
    );
  }

  const onRowDoubleClicked = useCallback(async (item) => {
    try {
      await getProjetos();
      const {
        id,
        ativo,
        nome,
        username,
        email,
        telefone,
        projetos,
        clientes,
        perfil
      } = item.data;
      setId(id);
      setAtivo(ativo);
      setNome(nome);
      setUsername(username);
      setPassword("");
      setEmail(email);
      setTelefone(telefone);
      setProjetos(projetos);
      setClientes(clientes)
      setPerfil(perfil);
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }, [getProjetos])

  function cardContent(usuario) {
    return (
      <CardContent>
        <CardBody>
          <h4>{usuario.nome}</h4>
          <br />
          <span>
            Perfil:{" "}
            {
              PerfisUsuarios.properties.find(p => p.value === usuario.perfil)
                .text
            }
          </span>
          <span>Ativo: {usuario.ativo ? "Sim" : "Não"}</span>
          <span>
            Projetos:{" "}
            {usuario.projetos.length
              ? usuario.projetos.map(p => p.descricao).join(", ")
              : "-"}
          </span>
        </CardBody>
        <div>
          <Button
            icon="edit"
            intent="primary"
            onClick={() => onRowDoubleClicked({ data: usuario })}
          />
        </div>
      </CardContent>
    );
  }

  const handleProjetoSelected = useCallback((projeto) => {
    const lista = [...projetos];
    const index = lista.findIndex(f => f.id === projeto.id);
    if (index < 0) {
      lista.push(projeto);
      setProjetos(lista);
    }
  }, [projetos])

  const handleProjetoRemoved = useCallback((index) => {
    const lista = [...projetos];
    if (index > -1) {
      lista.splice(index, 1);
      setProjetos(lista);
    }
  }, [projetos])

  const handleClienteSelected = useCallback((cliente) => {
    const lista = [...clientes];
    const index = lista.findIndex(f => f.id === cliente.id);
    if (index < 0) {
      lista.push(cliente);
      setClientes(lista);
    }
  }, [clientes])

  const handleClienteRemoved = useCallback((index) => {
    const lista = [...clientes];
    if (index > -1) {
      lista.splice(index, 1);
      setClientes(lista);
    }
  }, [clientes])

  function modalContent() {
    return (
      <Form>
        <Switch
          inline
          large
          checked={ativo}
          label="Ativo"
          onChange={evt => setAtivo(evt.currentTarget.checked)}
        />
        <Select
          notFilter
          label="Perfil"
          items={PerfisUsuarios.properties}
          textValue={item => item.text}
          itemKey="value"
          selected={PerfisUsuarios.properties.find(p => p.value === perfil)}
          onSelect={selected => setPerfil(selected.value)}
        />
        {perfil !== PerfisUsuarios.ADMIN && perfil !== PerfisUsuarios.SUPERVISOR && perfil !== PerfisUsuarios.SUPERVISOR_HYDRA ? (
          <Select
            multi
            label="Projetos"
            items={listaProjetos.filter(
              p => !projetos.find(proj => proj.id === p.id)
            )}
            textValue={item => item.descricao}
            labelValue={item => item.fazenda.nome}
            itemKey="id"
            selected={projetos}
            onSelect={selected => handleProjetoSelected(selected)}
            onRemoveItem={(tag, index) => handleProjetoRemoved(index)}
          />
        ) : null}
        {perfil === PerfisUsuarios.SUPERVISOR ? (
          <Select
            multi
            label="Clientes"
            items={listaClientes}
            textValue={item => item.nome}
            labelValue={item => item.nome}
            itemKey="id"
            selected={clientes}
            onSelect={selected => handleClienteSelected(selected)}
            onRemoveItem={(tag, index) => handleClienteRemoved(index)}
          />
        ) : null}
        <Input
          label="Nome"
          autoComplete="nome"
          placeholder="nome do usuário"
          value={nome}
          onChange={evt => setNome(evt.currentTarget.value)}
        />
        <Input
          label="E-mail"
          type="email"
          autoComplete="e-mail"
          placeholder="e-mail do usuário"
          value={email}
          onChange={evt => setEmail(evt.currentTarget.value)}
        />
        <PhoneInput
          autoComplete="telefone"
          label="Telefone"
          placeholder="número de telefone"
          value={telefone}
          onChange={value => setTelefone(value)}
        />
        <Input
          label="Login"
          autoComplete="login"
          placeholder="login do usuário"
          value={username}
          onChange={evt =>
            setUsername(
              String(evt.currentTarget.value)
                .trim()
                .toLocaleLowerCase()
            )
          }
        />
        <Input
          label="Senha"
          autoComplete="senha"
          placeholder="senha do usuário"
          value={password}
          onChange={evt => setPassword(evt.currentTarget.value)}
        />
      </Form>
    );
  }

  function modalActions() {
    return (
      <Button
        large
        icon="floppy-disk"
        intent="success"
        text="Salvar"
        onClick={() => setIsConfirmationOpen(true)}
        disabled={!canSave()}
      />
    );
  }

  const handleSave = useCallback(async () => {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const dados = {
        ativo,
        nome,
        username,
        password,
        perfil,
        projetos: projetos.map(p => ({ id: p.id })),
        clientes: clientes.map(c => ({ id: c.id })),
        cliente,
        email,
        telefone
      };
      if (!id) {
        await Api.postRequest("usuarios", dados);
      } else {
        console.log('>>>>>>>>>> clientes', dados.clientes)
        await Api.putRequest("usuarios", { ...dados, id });
      }

      setIsModalOpen(false);
      setIsLoading(false);
      presentDialog("Sucesso", "Usuário salvo com sucesso");
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
      setIsLoading(false);
      if (err.response) {
        if (err.response.status === 401) {
          setErrorCode(401);
          presentDialog("Erro", `Sessão expirada`);
        } else {
          setErrorCode(err.response.status);
          presentDialog(
            "Erro",
            `Ocorreu um erro ao salvar os dados. Erro: ${err.message}`
          );
        }
      }
    }
  }, [ativo, cliente, email, id, nome, password, perfil, presentDialog, projetos, telefone, username, clientes])

  const canSave = useCallback(() => {
    if (perfil === PerfisUsuarios.ADMIN || perfil === PerfisUsuarios.SUPERVISOR_HYDRA) {
      return id
        ? nome && username && (telefone.length === 10 || telefone.length === 11)
        : nome &&
            username &&
            (telefone.length === 10 || telefone.length === 11) &&
            password;
    } else if (perfil === PerfisUsuarios.SUPERVISOR) {
      return id
      ? nome &&
          clientes.length &&
          username &&
          (telefone.length === 10 || telefone.length === 11)
      : nome &&
          clientes.length &&
          username &&
          (telefone.length === 10 || telefone.length === 11) &&
          password;
    } else
      return id
        ? cliente.id &&
            nome &&
            projetos.length &&
            username &&
            (telefone.length === 10 || telefone.length === 11)
        : cliente.id &&
            nome &&
            projetos.length &&
            username &&
            (telefone.length === 10 || telefone.length === 11) &&
            password;
  }, [cliente.id, id, nome, password, perfil, projetos.length, telefone.length, username, clientes])

  return (
    <Container>
      <Navbar history={props.history} />

      <ElementsPresentation
        tableActions={tableActions()}
        onRowDoubleClicked={onRowDoubleClicked}
        columnDefs={colunas}
        rowData={listaUsuarios}
        cardContent={cardContent}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        modalTitle="Detalhes do usuário"
        modalActions={modalActions()}
        modalContent={modalContent()}
        loading={isLoading}
      />

      <Dialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirmar?"
        text="Deseja realmente salvar os dados?"
        actions={
          <>
            <Button
              icon="tick"
              text="Sim"
              intent="success"
              onClick={handleSave}
            />
            <Button
              icon="cross"
              text="Não"
              intent="danger"
              onClick={() => setIsConfirmationOpen(false)}
            />
          </>
        }
      />
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title={successTitle}
        text={successMessage}
        actions={
          <Button
            text="OK"
            onClick={() =>
              errorCode === 401
                ? props.history.push("/")
                : props.history.push("/dashboard")
            }
          />
        }
      />
    </Container>
  );
}
